import React from "react";
import {NavLink} from "react-router-dom";
import SubNavButton from "../../components/base/SubNavButton";
import LinksEnum from "../../components/enums/LinksEnum";
import BorderBottom from "../../components/border/BorderBottom";

interface DocumentationNavProps {
    page: string
}

interface TitleNavLinkProps {
    title: string, // Title visible in menu
    url: string, // Redirect to which page
    open: boolean, // Is the menu item open/closed
    subNav?: boolean, // Different color menu button
    mainPage?: boolean, // To make it also highlight in navbar if there is no Hash used.
}

const WorkshopNav: React.FC<DocumentationNavProps> = ({ page }) => {
        const titleSizeClass = " text-[1.1rem] ";
        const documentationPreUrl = LinksEnum.Workshop;


    const ArrowDownIcon = () => {
        return(<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-8 pl-2 w-7"
        >
            <path
                fillRule="evenodd"
                d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                clipRule="evenodd"
            />
        </svg>);
    }

    const TitleNavLink: React.FC<TitleNavLinkProps> = ({title, url, open, subNav, mainPage}) => {
        const isOnPage: boolean = window.location.pathname.includes(url) || open || mainPage === true;

        if (open) {
            return (
                <NavLink to={url} className={"flex rounded-lg mt-3 mb-3 py-2 " + (isOnPage ? "bg-[#468ef9] dark:bg-slate-950" : "bg-slate-800")}>
                    { !subNav ?
                        <span className={" ml-4 text-2xl font-bold" + (isOnPage ? " text-white dark:text-sky-500 " : "text-sky-500") + titleSizeClass}>{title}</span> :
                        <span className={"ml-4 text-2xl font-bold" + (isOnPage ? " text-white dark:text-sky-300 " : "text-sky-300 ") + titleSizeClass}>{title}</span> }
                </NavLink>
            );
        }else {
            return (
                <>
                    <NavLink to={url} className={"flex hover:dark:bg-slate-950 hover:bg-[#468ef9] rounded-lg hover:text-white py-2 px-3" }>
                        {!subNav ?
                            <span className={"flex-grow hover:text-white text-sky-500 pt-1 pl-1 text-[1.1rem] font-bold" + titleSizeClass}>{title}</span> :
                            <span className={"flex-grow hover:text-white text-sky-700 pt-1 pl-1 text-[1.1rem] font-bold" + titleSizeClass}>{title}</span>
                        }
                        <ArrowDownIcon/>
                    </NavLink>
                    { !subNav ? <BorderBottom /> : <></> }
                </>
            );
        }
    }

    const WorkshopSubNav = () => {
        const navLink = documentationPreUrl + '';
        if(page === "Introduction") {
            return(
                <>
                    <TitleNavLink title={"Introduction"} url={navLink + "#llms"} open={true} />
                    <SubNavButton anchorRef={navLink + "/transcript#transcript"} title="Transcript" />
                    <SubNavButton anchorRef={navLink + "/event-timeline#timeline"} title="Event Timeline" />
                    <BorderBottom />
                </>
            );
        }
        return(<TitleNavLink title={"Introduction"} url={navLink + "#llms"} open={false} />);
    }


    return (
        <div className="col-span-12 lg:col-span-3 hidden lg:block sticky top-8  z-50"
                 style={{"maxHeight": "95vh", "overflow": "auto", "marginTop": "50px"}}>
            <nav className="mt-10 px-2">
                <WorkshopSubNav />
            </nav>
        </div>);

}

export default WorkshopNav;