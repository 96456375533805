enum SectionIDEnum {
    // Framework (IPVE)
    FrameworkCraftC = "craft-c",
    FrameworkCraftR = "craft-r",
    FrameworkCraftA = "craft-a",
    FrameworkCraftF = "craft-f",
    FrameworkCraftT = "craft-t",

    FrameworkValidateI = "validate-i",
    FrameworkValidateN = "validate-n",
    FrameworkValidateG = "validate-g",

    FrameworkEnhanceAI = "enhance-ai",

    // Techniques
    TechniquesShotZero = "shot-zero",
    TechniquesShotSingle = "shot-single",
    TechniquesShotFew = "shot-few",
    TechniquesShotWhyImportant = "shot-why-important",

    // Injections
    InjectionWebsiteRobotTxt = "website-injection-robottxt",
    InjectionWebsiteTheInjection = "website-injection-the-injection",
    InjectionWebsiteAssessment = "website-injection-risk-assessment",

    InjectionImageIntroduction = "image-injection-the-image-injection",
    InjectionImageDocument = "image-injection-the-document-injection",
    InjectionImageAssessment = "image-injection-risk-assessment",

    InjectionCustomGPTPros = "custom-gpt-injection-pros",
    InjectionCustomGPTInjection = "custom-gpt-injection-the-injection",
    InjectionCustomGPTAssessment = "custom-gpt-injection-risk-assessment",

    InjectionArtPrompt = "artPrompt-injection",
    InjectionArtPromptExample = "artPrompt-injection-example",
    InjectionArtPromptAssessment = "artPrompt-injection-risk-assessment",
    InjectionArtPromptResearchPapers = "artPrompt-injection-research-papers",
}

export function NAVIGATE_TO_SECTION(url: SectionIDEnum): string {
    return `#${url}`;
}

export default SectionIDEnum;