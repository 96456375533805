import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect, useRef, useState} from "react";
import RoleEnum from "../../components/enums/RoleEnum";
import axios from "../../libs/axios";
import {
    CheckCircle,
    XCircle,
    Loader,
    ArrowUpCircle,
    ArrowDownCircle,
    Shield,
    Ban,
    Unlock,
    Award,
    Users, GraduationCap, MessageCircle
} from 'lucide-react';
import {useNavigate} from "react-router-dom";
import {Button} from "@headlessui/react";
import TicketStatusEnum from "../../components/enums/TicketStatusEnum";
import BaseButton from "../../components/base/Button";
import ReplyTicketModal from "../../components/dashboard/dashboard/ReplyTicketModal";


const Admin = () => {
    const { user } = useAuth({ middleware: 'admin' })
    const [userList, setUserList] = useState([]);
    const [ticketList, setTicketList] = useState([]);
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [currentTicketId, setCurrentTicketId] = useState(null);
    const [replyText, setReplyText] = useState('');

    const apiCallMadeRef = useRef(false);
    let navigate = useNavigate();

    const { csrf } = useAuth();

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Admin"

        // Get userList from /api/users and store in setUserList
        const fetchData = async () => {
            if (apiCallMadeRef.current) return;
            apiCallMadeRef.current = true;

            try {
                await csrf();
                const response = await axios.get("/admin/get-user-list");
                setUserList(response.data);
                if (response.status !== 401) {
                    const ticketResponse = await axios.get("/admin/get-ticket-list");
                    setTicketList(ticketResponse.data);
                }
            } catch (error) {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    navigate('/dashboard/overview');
                    window.location.reload();
                }
            }
        };

        fetchData();
    }, []);

    async function upgradeAccountToTakeTests(userID, roleID) {
        // Write for me a post to /admin/upgrade-user-role-1/$userID
        try {
            await csrf();
            const response = await axios.post(`/admin/upgrade-user-role/${userID}/${roleID}`);
            setUserList(userList.map(user => user.id === userID ? response.data : user));
        } catch (error) {
            //console.error(error);
        }
    }

    async function banAccount(userID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/ban-user/${userID}`);
            setUserList(userList.map(user => user.id === userID ? response.data : user));
        } catch (error) {
            //console.error(error);
        }
    }

    async function unBanAccount(userID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/unban-user/${userID}`);
            setUserList(userList.map(user => user.id === userID ? response.data : user));
        } catch (error) {
            //console.error(error);
        }
    }

    function getRole(roleNumber) {
        // Convert RoleEnum object to an array of role numbers
        const roleNumbers = Object.keys(RoleEnum).filter(key => !isNaN(Number(key))).map(Number);

        // If the role number exists in RoleEnum, return it
        if (roleNumbers.includes(roleNumber)) {
            return RoleEnum[roleNumber];
        }

        // If the role number doesn't exist in RoleEnum, return the highest role number
        return RoleEnum[Math.max(...roleNumbers)];
    }

    async function closeTicket(ticketId) {
        await csrf();
        axios.post(`/admin/close-ticket/${ticketId}`).then(() => {
            setTicketList(ticketList.map(ticket => ticket.id === ticketId ? {...ticket, status: 2} : ticket));
        }).catch((error) => {

        });
    }

    function replyToTicket(ticketId) {
        setCurrentTicketId(ticketId);
        setShowReplyModal(true);
    }

    async function handleReplySubmit() {
        try {
            await csrf();
            axios.post(`/ticket/reply`, {
                ticket_id: currentTicketId,
                message: replyText
            }).then(response => {
                // Update the ticket list
                setTicketList(ticketList.map(ticket => ticket.id === currentTicketId ? {
                    ...ticket,
                    updates: [...ticket.updates, {
                        user: user,
                        description: replyText,
                        updated_at: new Date().toISOString()
                    }],
                    status: response.data.new_ticket_status // update the status of the ticket
                } : ticket));
            });
            setShowReplyModal(false);
            setReplyText('');
        } catch (error) {
            // Do nothing
        }
    }

    const totalCertificates = userList.reduce((total, user) => total + (user.tests?.filter(test => test.certificate_id !== null).length || 0), 0);    const bannedUsersCount = userList.filter(user => user.banned_user).length;

    return(
        user && user?.role >= RoleEnum.Admin ?
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Admin Dashboard
                </h2>
            }>
            <div className="py-12 mx-3 lg:mx-0">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <div className="welcome-section bg-white overflow-hidden shadow-sm sm:rounded-lg mb-4 flex-grow">
                                <div className="p-6 bg-white">
                                    <h1 id="welcome" className="text-[2rem]">
                                        Welcome <span className="text-header-gradient">{user?.name}</span>!
                                    </h1>
                                    <p>Here are some tools for admins!</p>
                                </div>
                            </div>
                            <div className="welcome-section bg-white overflow-hidden shadow-sm sm:rounded-lg mb-4 flex-grow">
                                <div className="p-6 bg-white">
                                    <h2 id="quick-buttons" className="text-[2rem]">
                                        Admin tools
                                    </h2>
                                    <BaseButton url={"/dashboard/admin/test/questions"} styles={"px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition-colors"}>Test questions</BaseButton>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex flex-col total-certificates bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                <div className="p-6 bg-white">
                                    <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-12 items-center">
                                        <div className="flex items-center">
                                            <Award className={"text-yellow-600 w-8 h-8 mr-2"} />
                                            <p className={"mt-5 ml-2"}>Certificates: {totalCertificates}</p>
                                        </div>
                                        <div className="flex items-center">
                                            <Users className={"text-blue-600 w-8 h-8 mr-2"} />
                                            <p className={"mt-5 ml-2"}>Users: {userList.length}</p>
                                        </div>
                                        <div className="flex items-center">
                                            <Users className={"text-red-600 w-8 h-8 mr-2"} />
                                            <p className={"mt-5 ml-2"}>Banned: {bannedUsersCount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="users-overview bg-white overflow-hidden shadow-sm sm:rounded-lg mb-4">
                        <div className="p-6 bg-white overflow-x-auto">
                            <table className="w-full text-left table-auto">
                                <thead className="bg-gray-200">
                                <tr>
                                    <th className="px-4 py-2">ID</th>
                                    <th className="px-4 py-2">Username</th>
                                    <th className="px-4 py-2">Role</th>
                                    <th className="px-4 py-2">Created at</th>
                                    <th className="px-4 py-2 text-center">Tests taken</th>
                                    <th className="px-4 py-2 text-center">Certification ID</th>
                                    <th className="px-4 py-2 text-center">Role</th>
                                    <th className="px-4 py-2 text-center">Ban</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userList.length === 0 ? (
                                    <tr className="border-b border-gray-200">
                                        <td colSpan={5} className={"p-5"}>No users found.</td>
                                    </tr>
                                ) : (
                                    (userList.map((currentUser) => (
                                    <tr key={currentUser.id} className={`border-b border-gray-200 ${currentUser.banned_user ? 'line-through' : ''}`}>
                                        <td className="px-4 py-2">{currentUser.id}</td>
                                        <td className="px-4 py-2">{currentUser.name}</td>
                                        <td className="px-4 py-2">{getRole(currentUser.role)}</td>
                                        <td className="px-4 py-2">{new Date(currentUser.created_at).toLocaleString('en-US',
                                            { month: 'long', day: '2-digit', year: 'numeric'})
                                        }</td>
                                        <td className="text-center">
                                            {currentUser?.tests?.some(test => test !== null) ?
                                                currentUser.tests.find(test => test !== null).in_progress ?
                                                    <Loader className={"text-blue-700 mx-auto animate-spin"} />
                                                    : <CheckCircle className={"text-green-700 mx-auto"} />
                                                : <XCircle className={"text-gray-400 mx-auto"} />}
                                        </td>
                                        <td className="text-center">
                                            {currentUser?.tests?.some(test => test.certificate_id !== null) ? currentUser.tests.find(test => test.certificate_id !== null).certificate_id : <XCircle className={"text-gray-400 mx-auto"} />}
                                        </td>
                                        <td className="text-center">
                                            {user.role >= RoleEnum.Admin && user.role > currentUser.role && user.id !== currentUser.id && (
                                                <>
                                                    {currentUser.role >= RoleEnum.DefaultUser && (
                                                    <Button onClick={() => upgradeAccountToTakeTests(currentUser.id, RoleEnum.Registered)}
                                                            title={"Downgrade account to take test"}
                                                            className={"mt-1"}
                                                            disabled={currentUser.banned_user}
                                                    >
                                                        <ArrowDownCircle className={`mx-auto ${currentUser.banned_user ? 'text-gray-300 ' : 'text-red-500 cursor-pointer hover:text-red-800'}`} />
                                                    </Button>)}
                                                    {currentUser.role === RoleEnum.Registered && (
                                                    <Button onClick={() => upgradeAccountToTakeTests(currentUser.id, RoleEnum.DefaultUser)}
                                                            title={"upgrade account to take test"} className={"mt-1"}
                                                            disabled={currentUser.banned_user}>
                                                        <ArrowUpCircle className={`mx-auto ${currentUser.banned_user ? 'text-gray-300 ' : 'text-blue-500 cursor-pointer hover:text-blue-800'}`} />
                                                    </Button>)}
                                                    {user.role >= RoleEnum.SuperAdmin && (
                                                        <>
                                                            <Button onClick={() => upgradeAccountToTakeTests(currentUser.id, RoleEnum.Trainer)}
                                                                    title={"Upgrade to Trainer"} className={`ml-3 mt-1`}
                                                                    disabled={currentUser.banned_user}>
                                                                <GraduationCap className={`mx-auto ${currentUser.banned_user ? 'text-gray-300 ' : 'text-green-600 hover:text-green-900 cursor-pointer'}`} />
                                                            </Button>
                                                            <Button onClick={() => upgradeAccountToTakeTests(currentUser.id, RoleEnum.Admin)}
                                                                    title={"Upgrade to admin"} className={`ml-3 mt-1`}
                                                                    disabled={currentUser.banned_user}>
                                                                <Shield className={`mx-auto ${currentUser.banned_user ? 'text-gray-300 ' : 'text-yellow-600 hover:text-yellow-900 cursor-pointer'}`} />
                                                            </Button>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                        <td className="px-4 py-2 text-center">
                                            {user.role >= RoleEnum.SuperAdmin && user.id !== currentUser.id &&
                                                (currentUser.banned_user ? (
                                                <Button onClick={() => unBanAccount(currentUser.id)} title={"Unban account"} className={"mt-1"}>
                                                    <Unlock className={"text-green-700 mx-auto cursor-pointer hover:text-red-800"} />
                                                </Button>
                                            ) : (
                                                <Button onClick={() => banAccount(currentUser.id)} title={"Ban account"} className={"mt-1"}>
                                                    <Ban className={"text-red-500 mx-auto cursor-pointer hover:text-red-800"} />
                                                </Button>
                                            ))}
                                        </td>
                                    </tr>
                                ))))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="users-overview bg-white overflow-hidden shadow-sm sm:rounded-lg">
                        <div className="p-6 bg-white overflow-x-auto">
                            <table className="w-full text-left table-auto">
                                <thead className="bg-gray-200">
                                <tr>
                                    <th className="px-4 py-2">ID</th>
                                    <th className="px-4 py-2">(user_id) User</th>
                                    <th className="px-4 py-2">Category</th>
                                    <th className="px-4 py-2">Status</th>
                                    <th className="px-4 py-2">Description</th>
                                    <th className="px-4 py-2">Created at</th>
                                    <th className="px-4 py-2">Reply</th>
                                    <th className="px-4 py-2">Close</th>
                                </tr>
                                </thead>
                                <tbody>
                                {ticketList.length === 0 ? (
                                    <tr className="border-b border-gray-200">
                                        <td colSpan={5} className={"p-5"}>No tickets found.</td>
                                    </tr>
                                ) : (
                                    (ticketList.map((ticket) => (
                                        <tr key={ticket.id} className={`border-b border-gray-200 ${ticket.status === TicketStatusEnum.Closed ? 'line-through' : ''}`}>
                                            <td className="px-4 py-2">{ticket.id}</td>
                                            <td className="px-4 py-2">({ticket.user_id}) {ticket.name}</td>
                                            <td className="px-4 py-2">{ticket.category === 'REQUEST_TEST_ACCESS' ? 'TEST_ACCESS' : ticket.category.toUpperCase()}</td>
                                            <td className="px-4 py-2">
                                                <span className={ticket.status === TicketStatusEnum.Open ? 'text-green-600' : ticket.status === TicketStatusEnum.Pending ? 'text-yellow-600' : 'text-red-600'}>
                                                  {ticket.status === TicketStatusEnum.Open ? 'Open' : ticket.status === TicketStatusEnum.Pending ? 'Pending' : 'Closed'}
                                              </span>
                                            </td>
                                            <td className="px-4 py-2">{ticket.description}</td>
                                            <td className="px-4 py-2">{new Date(ticket.created_at).toLocaleString('en-US',
                                            { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}
                                            </td>
                                            <td className={"px-4 py-2 text-center"}>
                                            {(ticket.updates.length === 0 && ticket.status === TicketStatusEnum.Closed) ? null : (
                                                <Button onClick={() => {replyToTicket(ticket.id)}}>
                                                    <div className="relative">
                                                        <MessageCircle size={27} className={"text-blue-500 cursor-pointer hover:text-blue-800"} />
                                                        <span className="absolute top-[0.3rem] right-[0.350rem] bg-blue-500 text-white rounded-full w-4 h-4 text-xs flex items-center justify-center">
                                                            {ticket.updates.length}
                                                        </span>
                                                    </div>
                                                </Button>)}
                                            </td>
                                            <td className={"px-4 py-2 text-center"}>
                                                {ticket.status === TicketStatusEnum.Closed ? null : (
                                                <Button onClick={() => {closeTicket(ticket.id)}}>
                                                    <XCircle className={"text-red-500 cursor-pointer hover:text-red-800"} />
                                                </Button>)}
                                            </td>
                                        </tr>
                                    ))))
                                }
                                </tbody>
                            </table>

                            {showReplyModal && (
                               <ReplyTicketModal
                                    showReplyModal={showReplyModal}
                                    setShowReplyModal={setShowReplyModal}
                                    ticketList={ticketList}
                                    currentTicketId={currentTicketId}
                                    replyText={replyText}
                                    setReplyText={setReplyText}
                                    handleReplySubmit={handleReplySubmit}
                                    status={ticketList.find(ticket => ticket.id === currentTicketId)?.status}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
        :
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Authenticating...
                </h2>
            }>
        </AppLayout>
    )
}

export default Admin
