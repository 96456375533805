import React, {ReactNode, useEffect, useState} from 'react';
import Navbar from '../components/base/Navbar'
import Footer from '../components/base/Footer'
import CookieBanner from '../components/CookieBanner'
import {useLocation} from "react-router-dom";

interface SlotComponentProps {
    children: ReactNode;
}

const Default: React.FC<SlotComponentProps> = ({ children }) =>  {
    const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === "true");

    // Menu
    const toggleDarkMode = () => { setDarkMode((prevMode) => !prevMode); };
    const [mobileMenuOpen, setMobileMenuOpen] = useState<Boolean>(false);
    const toggleSidebar = (open: boolean) => { setMobileMenuOpen(open); }
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        const isDarkMode = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDarkMode);

        // Close menu when resizing website.
        window.addEventListener("resize", () => {
            if (window.innerWidth !== dimensions.width) {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth
                });
                toggleSidebar(false);
            }
        }, false);
    }, []);

    useEffect(() => {
      document.documentElement.classList.toggle('dark', darkMode);
      localStorage.setItem('darkMode', (darkMode ? "true" : "false"));
      const getDarkIcon = document.getElementById("theme-toggle-dark-icon");
      const getDarkIconMob = document.getElementById("theme-toggle-dark-icon-mob");
      const getLightIcon = document.getElementById("theme-toggle-light-icon");
      const getLightIconMob = document.getElementById("theme-toggle-light-icon-mob");
      const getHTMLTag = document.getElementById("root");
        if (getDarkIcon !== null && getLightIcon !== null &&
            getDarkIconMob !== null && getLightIconMob !== null) {
            if (darkMode) {
                getDarkIcon.classList.add('hidden');
                getLightIcon.classList.remove('hidden');
                getDarkIconMob.classList.add('hidden');
                getLightIconMob.classList.remove('hidden');
                getHTMLTag?.classList.add("dark");
                getHTMLTag?.classList.remove("light");
            } else if (getLightIcon) {
                getLightIcon.classList.add('hidden');
                getDarkIcon.classList.remove('hidden');
                getLightIconMob.classList.add('hidden');
                getDarkIconMob.classList.remove('hidden');
                getHTMLTag?.classList.add("light");
                getHTMLTag?.classList.remove("dark");
            }
        }
    }, [darkMode]);

    const location = useLocation();
    // Close menu when changing location
    useEffect(() => {
        if (mobileMenuOpen) { toggleSidebar(false); }
    }, [location.pathname + location.hash]);

  return (
    <div className="min-h-screen font-sans antialiased relative">
      <div className="relative">
        <Navbar toggleDarkMode={toggleDarkMode} toggleSidebar={toggleSidebar} open={mobileMenuOpen} /> {/*  toggleDarkMode={toggleDarkMode} (add if you want darkmode toggle) */}
         <span className={`${mobileMenuOpen ? "hidden" : "block"}`}>
             {/* Content */}
             {children}
            <Footer/>
            <CookieBanner mustAccept={false} />
        </span>
      </div>
    </div>
  );
};

export default Default;
