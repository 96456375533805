// Components
import React, {useEffect} from "react";
import DocumentationNav from "./DocumentationNav";
import BaseButton from "../../components/base/Button";

function FrameworkIntroduction() {

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Framework introduction";
  }, []);

  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="introduction">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="FrameworkIntroduction" />

              <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="framework-introduction"
                   data-aos="fade-up"
                   data-aos-delay="100">
                <h1 className="text-bold"><span className="text-header-gradient">Framework</span> introduction</h1>
                  <p>The Crafting AI Prompts Framework, divided into an IPE and IVPE focussed part, empowers users to leverage Generative AI effectively for both writing and visual content generation, while ensuring ethical and responsible use.</p>
                  <p>This division recognizes that while textual and visual outputs follow the same overarching approach and adhere to the same ethical guidelines, they require distinct considerations tailored to the specific nature of the outputs.
                      Hence, separate sections for IPE and IVPE are provided to focus on the unique instructions necessary for optimizing user outcomes in each domain.</p>

                  <p>The <strong><a href="/documentation/framework#documentation" className="text-bold underline" title='IPE Framework'>Framework section</a></strong> details the IPE aspect,
                      while the <strong><a href="/documentation/framework/visual#documentation" className="text-bold underline" title='IVPE Framework'>IVPE Framework section</a></strong> is dedicated to IVPE.
                      If the terms IPE and IVPE are new to you, please refer to the <strong><a href="/documentation/prompt-engineering#prompt-engineering" className="text-bold underline" title='Prompt Engineering'>Prompt Engineering section</a></strong> of this guide for a comprehensive understanding before proceeding.</p>

                  <p>By understanding and applying the CRAFTING AI prompts framework, users can enhance their engagement and effectiveness in utilizing AI tools across diverse tasks.</p>

                  <div className={"pt-5"}>
                      <BaseButton url={"/documentation/framework#framework"} styles="max-w-full px-8 py-4 mr-5 mb-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                          IPE Framework section
                      </BaseButton>
                      <BaseButton url={"/documentation/framework/visual#framework"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                          IVPE Framework section
                      </BaseButton>
                  </div>
              </div>
        </div>
      </section>
  );
}

export default FrameworkIntroduction;
