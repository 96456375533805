import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect, useRef, useState} from "react";
import RoleEnum from "../../components/enums/RoleEnum";
import {useNavigate} from "react-router-dom";
import axios from "../../libs/axios";
import {Check, ChevronDown, ChevronUp, CircleCheck, Edit} from 'lucide-react';
import QuestionCategoryEnum from "../../components/enums/QuestionCategoryEnum";
import Pagination from "../../components/dashboard/dashboard/Pagination";
import TestLevelEnum from "../../components/enums/TestLevelEnum";

const AdminTests = () => {
    const { user } = useAuth({ middleware: 'admin' })
    const [questionList, setQuestionList] = useState([]);
    const [openQuestion, setOpenQuestion] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [editedQuestion, setEditedQuestion] = useState({});
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deletingQuestion, setDeletingQuestion] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust this value as needed
    const [error, setError] = useState(null);

    const minQuestionsPerCategory = {
        [QuestionCategoryEnum.ADVERSARIAL]: 2,
        [QuestionCategoryEnum.PE]: 3,
        [QuestionCategoryEnum.TECHNIQUE]: 5,
        [QuestionCategoryEnum.IPE]: 10,
        [QuestionCategoryEnum.ARCHITECTURE]: 0
    };

    // Calculate pagination
    const [currentQuestions, setCurrentQuestions] = useState([]);

    const apiCallMadeRef = useRef(false);
    let navigate = useNavigate();

    // Count the number of questions in each category
    const countQuestionsInCategories = () => {
        return Object.values(QuestionCategoryEnum).reduce((counts, category) => {
            counts[category] = questionList.filter(question => question.category === category).length;
            return counts;
        }, {});
    };

    // Get the counts
    const categoryCounts = countQuestionsInCategories();

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Admin Tests"

        // Get userList from /api/users and store in setUserList
        const fetchData = async () => {
            if (apiCallMadeRef.current) return;
            apiCallMadeRef.current = true;

            try {
                const response = await axios.get("/admin/test/questions/");
                setQuestionList(response.data);

            } catch (error) {
                if (error.response && error.response.status === 403) {
                    navigate('/dashboard/overview');
                }
            }
        };

        fetchData();

    }, []);

    useEffect(() => {
        // Calculate the index of the first and last question on the current page
        const indexOfLastQuestion = currentPage * itemsPerPage;
        const indexOfFirstQuestion = indexOfLastQuestion - itemsPerPage;

        // Get the questions for the current page
        setCurrentQuestions(questionList.slice(indexOfFirstQuestion, indexOfLastQuestion));
    }, [questionList, currentPage]);

    const handleEdit = (questionIndex) => {
        if (editingQuestion === questionIndex) {
            setEditingQuestion(null);
            setEditedQuestion({});
        } else {
            setEditingQuestion(questionIndex);
            setEditedQuestion(currentQuestions[questionIndex]);
        }
    };

    const handleSubmit = async () => {
        // Get the answer values of the test_answer array
        const answerValues = editedQuestion.test_answer.map(answer => answer.answer);

        // Create a set from the answer values
        const answerSet = new Set(answerValues);

        // Check if the size of the set is less than the length of the test_answer array
        if (answerSet.size < editedQuestion.test_answer.length) {
            // If it is, there are duplicate answers
            setError('The answers must be different');
            return;
        }else {
            try {
                const questionId = currentQuestions[editingQuestion].id;
                const questionIndexInList = questionList.findIndex(question => question.id === questionId);
                const response = await axios.post('/admin/test/question/update', editedQuestion);
                // Update the questionList state with the updated question
                setQuestionList(prev => prev.map((question, index) => index === questionIndexInList ? response.data : question));
                setError("");
            } catch (error) {
                setError('Something went wrong posting it to the database.');
            }
            setEditingQuestion(null);
            setEditedQuestion({});
        }
    };

    const handleInputChange = (field, value, answerIndex = null) => {
        setError('');
        setEditedQuestion(prev => {
            if (field === 'correctAnswer') {
                const updatedAnswers = prev.test_answer.map((answer, index) => ({
                    ...answer,
                    correct_answer: index === value ? 1 : 0
                }));
                return { ...prev, test_answer: updatedAnswers, correctAnswer: value };
            } else if (answerIndex !== null) {
                const updatedAnswers = [...prev.test_answer];
                updatedAnswers[answerIndex] = { ...updatedAnswers[answerIndex], [field]: value };
                return { ...prev, test_answer: updatedAnswers };
            }
            return { ...prev, [field]: value };
        });
    };

    const sortQuestionsByCategory = () => {
        const sortedQuestions = [...questionList].sort((a, b) => a.category.localeCompare(b.category));
        setQuestionList(sortedQuestions);
    };

    const sortQuestionsById = () => {
        const sortedQuestions = [...questionList].sort((a, b) => a.id - b.id);
        setQuestionList(sortedQuestions);
    };

    const [showPopup, setShowPopup] = useState(false);
    const [newQuestion, setNewQuestion] = useState({
        question: '',
        answers: ['', '', '', ''],
        category: '',
        correctAnswer: 0
    });

    const handleNewQuestionChange = (field, value, index = null) => {
        setNewQuestion(prev => {
            if (index !== null) {
                const updatedAnswers = [...prev.answers];
                updatedAnswers[index] = value;
                return { ...prev, answers: updatedAnswers };
            }
            return { ...prev, [field]: value };
        });
    };

    const handleNewQuestionSubmit = async () => {
        // Get the answer values of the test_answer array
        const answerValues = newQuestion.answers.map(answer => answer.trim());

        // Create a set from the answer values
        const answerSet = new Set(answerValues);

        // Check if the size of the set is less than the length of the test_answer array
        if (answerSet.size < newQuestion.answers.length) {
            // If it is, there are duplicate answers
            setError('The answers must be different');
            return;
        }else {
            try {
                const response = await axios.post('/admin/test/question/create', newQuestion);
                setQuestionList(prev => [...prev, response.data]);
                setNewQuestion({
                    question: '',
                    answers: ['', '', '', ''],
                    category: '',
                    correctAnswer: 0
                });

                setError("");
            } catch (error) {
                setError('Something went wrong posting it to the database.');
            }

            setShowPopup(false);
        }
    };

    const handleDeleteClick = (questionIndex) => {
        setDeletingQuestion(questionIndex);
        setShowDeletePopup(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const questionId = currentQuestions[deletingQuestion].id;
            const questionIndexInList = questionList.findIndex(question => question.id === questionId);
            await axios.delete(`/admin/test/question/delete/${questionId}`);
            setQuestionList(prev => prev.filter((_, index) => index !== questionIndexInList));

            // Check if the last question on the current page is being deleted
            if (deletingQuestion === currentQuestions.length - 1 && currentPage === Math.ceil(questionList.length / itemsPerPage) && questionList.length % itemsPerPage === 1) {
                setCurrentPage(prev => prev - 1);
            }
        } catch (error) {
            setError('Something went wrong deleting the question.');
        }
        setDeletingQuestion(null);
        setShowDeletePopup(false);
    };

    return(
        user && user?.role >= RoleEnum.Admin ?
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Admin Tests Dashboard
                </h2>
            }>
            <div className="py-12 mx-3 lg:mx-0">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <div className="welcome-section bg-white overflow-hidden shadow-sm sm:rounded-lg mb-4 flex-grow">
                                <div className="p-6 bg-white">
                                    <h1 id="welcome" className="text-[2rem]">
                                        Welcome <span className="text-header-gradient">{user?.name}</span>!
                                    </h1>
                                    <p>Here you can edit tests!</p>

                                    <div className="flex flex-wrap">
                                        {Object.entries(categoryCounts).map(([category, count]) => (
                                            <span key={category} className={`inline-flex items-center py-3 pl-3 pr-1 rounded-full text-sm font-medium mr-2 mb-2 border transition-colors duration-200 ease-in-out  ${count >= minQuestionsPerCategory[category] ? 'bg-green-500 text-white' : 'bg-red-100 text-red-800 border-red-300 hover:bg-red-200'}`}>
                                                <span className="font-bold">{category}</span>
                                                <span className={`ml-2 text-white rounded-full px-2 ${count >= minQuestionsPerCategory[category] ? 'bg-green-700' : 'bg-red-500'}`}>{count} | {minQuestionsPerCategory[category]}</span>
                                            </span>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {showPopup && (
                        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <form onSubmit={(e) => { e.preventDefault(); handleNewQuestionSubmit(); }} className="bg-white rounded-lg shadow-lg w-2/3">
                                <h2 className="text-white bg-blue-500 rounded-t-lg p-5">Add new question</h2>
                                <div className={"px-8 pb-8"}>
                                    {error && <div
                                        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
                                        role="alert">
                                        {error}
                                    </div>
                                    }

                                    <label className="block mb-4">
                                        <span className="text-gray-700">Question:</span>
                                        <input type="text" value={newQuestion.question} onChange={(e) => handleNewQuestionChange('question', e.target.value)} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm border border-gray-300 p-2" />
                                    </label>
                                    <label className="block mb-4">
                                        <span className="text-gray-700">Add to test:</span>
                                        <select value={newQuestion.testCat} onChange={(e) => handleNewQuestionChange('testCat', e.target.value)} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm border border-gray-300 p-2">
                                            <option value="" disabled>Add to test</option>
                                            {Object.values(TestLevelEnum).map((level) => (
                                                <option key={level} value={level}>
                                                    {level}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    <label className="block mb-4">
                                        <span className="text-gray-700">Category:</span>
                                        <select value={newQuestion.category} onChange={(e) => handleNewQuestionChange('category', e.target.value)} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm border border-gray-300 p-2">
                                            <option value="" disabled>Select a category</option>
                                            {Object.values(QuestionCategoryEnum).map((category) => (
                                                <option key={category} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    {newQuestion.answers.map((answer, index) => (
                                        <div key={index} className="mb-4 flex items-center">
                                            <label className="block flex-grow mr-4">
                                                <span className="text-gray-700">Answer {index + 1}:</span>
                                                <input type="text" value={answer} onChange={(e) => handleNewQuestionChange('answers', e.target.value, index)} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm border border-gray-300 p-2" />
                                            </label>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleNewQuestionChange('correctAnswer', index);
                                                }}
                                                className="mr-2"
                                            >
                                                {newQuestion.correctAnswer === index ? <CircleCheck size={24} className="text-green-700 mt-6" /> : <CircleCheck size={24} className="text-gray-300 mt-6" />}
                                            </button>
                                        </div>
                                    ))}
                                    <div className="flex justify-end items-center mt-8 mr-12">
                                        <button
                                            onClick={() => setShowPopup(false)}
                                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className={`hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ` + (!newQuestion.question || !newQuestion.category || newQuestion.answers.some(answer => !answer) ? 'bg-gray-300' : 'bg-blue-500')}
                                            disabled={!newQuestion.question || !newQuestion.category || newQuestion.answers.some(answer => !answer)}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}

                    {user.role >= RoleEnum.Admin && (
                        <div>
                            <div className="users-overview bg-white overflow-hidden shadow-sm sm:rounded-lg mt-5">
                                <div className="p-6 bg-white overflow-x-auto">
                                    <div className="flex justify-between items-center mb-4">
                                        <h2 className={"mb-0"}>Questions: ({questionList.length})</h2>

                                        <div>
                                            <button
                                                onClick={sortQuestionsById}
                                                className="bg-blue-500 text-sm hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                                            >
                                                Sort by ID
                                            </button>
                                            <button
                                                onClick={sortQuestionsByCategory}
                                                className="bg-blue-500 text-sm hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                                            >
                                                Sort by Category
                                            </button>
                                            <button
                                                onClick={() => setShowPopup(true)}
                                                className="bg-green-800 text-sm text-white font-bold py-2 px-4 rounded"
                                            >
                                                Create New Question
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex flex-col space-y-4">
                                        {currentQuestions.map((question, questionIndex) => (
                                            <div key={questionIndex} className="bg-white shadow-md rounded-lg p-6 relative pointer">
                                                <div className="flex-grow pr-8 cursor-pointer" onClick={() => { if (editingQuestion !== questionIndex) setOpenQuestion(openQuestion === questionIndex ? null : questionIndex); }}>
                                                    {error && <div
                                                        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
                                                        role="alert">
                                                        {error}
                                                    </div>
                                                    }
                                                    {editingQuestion === questionIndex ? (
                                                        <label className="block mb-4">
                                                            <span className="text-gray-700">Category:</span>
                                                            <select value={editedQuestion.category || question.category} onChange={(e) => handleInputChange('category', e.target.value)} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm border border-gray-300 p-2">
                                                                {Object.values(QuestionCategoryEnum).map((category) => (
                                                                    <option key={category} value={category}>
                                                                        {category}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </label>
                                                    ) : (
                                                        <span className={`text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded
                                                            ${question.category === QuestionCategoryEnum.IPE ? `bg-blue-800` :
                                                                question.category === QuestionCategoryEnum.PE ? `bg-green-800` :
                                                                    question.category === QuestionCategoryEnum.ADVERSARIAL ? `bg-red-800` :
                                                                        question.category === QuestionCategoryEnum.TECHNIQUE ? `bg-yellow-800` :
                                                                            `bg-gray-800`
                                                        }`}>
                                                            Category: {question.category}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="flex justify-between items-start w-full mt-3 mb-2">
                                                    <div className="flex-grow pr-8 cursor-pointer" onClick={() => { if (editingQuestion === null) setOpenQuestion(openQuestion === questionIndex ? null : questionIndex); }}>
                                                        {editingQuestion === questionIndex ? (
                                                            <input
                                                                type="text"
                                                                value={editedQuestion.question || question.question}
                                                                onChange={(e) => handleInputChange('question', e.target.value)}
                                                                className="text-lg mb-0 font-normal bg-gray-100 rounded p-2 w-full"
                                                            />
                                                        ) : (
                                                            <h2 className="text-lg mb-0 font-normal">{question.question}</h2>
                                                        )}
                                                    </div>
                                                    {editingQuestion !== questionIndex &&
                                                        <button
                                                            onClick={() => { if (editingQuestion !== questionIndex) setOpenQuestion(openQuestion === questionIndex ? null : questionIndex)}}
                                                            className="text-gray-500 hover:text-gray-700 transition-colors"
                                                        >
                                                            {openQuestion === questionIndex ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                                                        </button>
                                                    }
                                                </div>

                                                {openQuestion === questionIndex && (
                                                    <div className="mt-4 space-y-2">
                                                        {(editingQuestion === questionIndex ? editedQuestion.test_answer : question.test_answer).map((answer, answerIndex) => (
                                                            <div key={answerIndex} className={`p-3 rounded ${answer.correct_answer ? 'bg-green-100' : 'bg-gray-100'}`}>
                                                                <div className="flex items-center">
                                                                    <input
                                                                        type="text"
                                                                        value={answer.answer}
                                                                        onChange={(e) => handleInputChange('answer', e.target.value, answerIndex)}
                                                                        className="bg-transparent rounded p-1 flex-grow"
                                                                    />
                                                                    {editingQuestion === questionIndex && (
                                                                        <button
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                handleInputChange('correctAnswer', answerIndex);
                                                                            }}
                                                                            className="ml-2"
                                                                        >
                                                                            {editedQuestion.correctAnswer === answerIndex ? <CircleCheck size={24} className="text-green-500" /> : <CircleCheck size={24} className="text-gray-300" />}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}

                                                        <div className="flex justify-end items-center pt-3">
                                                            {editingQuestion === questionIndex ? (
                                                                <>
                                                                    <button
                                                                        onClick={() => handleEdit(questionIndex)}
                                                                        className="text-sm bg-gray-200 mr-3 hover:bg-gray-300 text-gray-800 py-1 px-3 rounded transition-colors"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        onClick={() => handleSubmit(questionIndex)}
                                                                        className="text-sm bg-green-500 hover:bg-green-600 text-white py-1 px-3 rounded transition-colors"
                                                                    >
                                                                    <Check size={16} className="inline mr-1" />
                                                                        Submit
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        onClick={() => handleDeleteClick(questionIndex)}
                                                                        className="text-sm bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded transition-colors mr-2"
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                    <button
                                                                        onClick={() => handleEdit(questionIndex)}
                                                                        className="text-sm bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded transition-colors"
                                                                    >
                                                                        <Edit size={16} className="inline mr-1" />
                                                                        Edit
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>

                                                        {showDeletePopup && (
                                                            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                                                                <div className="bg-white p-6 rounded shadow-lg w-1/2">
                                                                    <p>Are you sure you want to delete this question?</p>
                                                                    <div className="flex justify-end mt-4">
                                                                        <button
                                                                            onClick={() => setShowDeletePopup(false)}
                                                                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                        <button
                                                                            onClick={handleDeleteConfirm}
                                                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Pagination currentPage={currentPage} totalPages={Math.ceil(questionList.length / itemsPerPage)} onPageChange={setCurrentPage} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </AppLayout>
        :
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Authenticating...
                </h2>
            }>
        </AppLayout>
    )
}

export default AdminTests
