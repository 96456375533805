import React from "react";

interface LabelProps {
    category?: string
    classifier?: string
    text?:string
}

const PromptComponentLabel: React.FC<LabelProps> = ({ text }) => {
    return(<div className="text-xs font-semibold h-full text-center py-4 px-2 rounded block text-white bg-sky-900 uppercase mr-2">
                <span className={"inline-flex items-center h-full"}> {text}</span>
            </div>);
}

export default PromptComponentLabel;