enum TestLevelEnum {
    CAIP1_IPE = "CAIP1_IPE" ,
    CAIP1_IVPE = "CAIP1_IVPE",
    CAIP2_IPE = "CAIP2_IPE",
    CAIP2_IVPE = "CAIP2_IVPE",
    CAIP3_IPE = "CAIP3_IPE",
    CAIP3_IVPE = "CAIP3_IVPE",
}


export default TestLevelEnum;