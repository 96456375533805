// Components
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import DocumentationNav from "./DocumentationNav";
import BaseButton from "../../components/base/Button";

function PromptTechniquesDocumentation() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Prompting Techniques"
  }, []);

  // /*
  // * Only show the handout version of the page.
  // * */
  // if (window.location.hash.includes("#handout-techniques")) {
  //   sessionStorage.setItem('handout-techniques', "true");
  // }
  // sessionStorage.setItem('handout-framework', "false"); // Set false, seems like it should be available.

  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="prompt-techniques">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="PromptTechniques" />

              <div className={"col-span-12 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section lg:col-span-9"}
                   id="techniques"
                   data-aos="fade-up"
                   data-aos-delay="100">
                <h1 className="text-bold">Prompt Engineering <span className="text-header-gradient">Techniques</span></h1>
                <p>In addition to understanding the core framework, mastering various prompt engineering strategies is crucial for enhancing the effectiveness and consistency of Large Language Models (LLMs). This article delves into a range of techniques for crafting prompts that enable more sophisticated tasks. Each section will also make connections back to the foundational framework, highlighting its importance in mastering these techniques.</p>

                <hr />

                <h2 id="zero-single-few-shot"><span className="text-header-gradient">Zero, Single, Few Shot</span> prompting</h2>
                <p>When employing Generative AI, understanding Zero, Single, and Few-Shot prompts is crucial. These terms refer to the amount of task-specific information provided to the model. Yes, Zero, Single, and Few-Shot prompts are essential concepts. The models we're working with, such as ChatGPT, are trained on a vast amount of data. However, there might be cases where you refer to data that is not available to the model. For instance, the free version of ChatGPT, as I wrote this, is not connected to the internet and was trained only until 2021. Therefore, any events or information occurring after that date are not accessible to the model, just like all "real-time" data (I will provide examples later on).
                  Let's dive into the differences between Zero, Single, and Few-Shot prompts.</p>

                <h3 id="shot-zero"><span className="text-header-gradient">Zero shot</span> prompting</h3>
                <p>A <strong className="dark:text-white">Zero Shot Prompting</strong> enables a model to make predictions about previously unseen data without the need for any additional training. Think about data that is not in the training data of ChatGPT. If this is the case, but you're referring to it, then it'll guess the outcome for you (it simply cannot answer it based on the available data).</p>
                <p className="block p-4 m-4 bg-sky-100 rounded-md dark:bg-sky-950">No task-specific examples; the model relies solely on its pre-training to attempt the task.</p>

                <h3 id="shot-single"><span className="text-header-gradient">Single shot</span> prompting</h3>
                <p>In <strong className="dark:text-white">single-shot prompting</strong>, the model is given a limited amount of task-specific information or a few examples as a prompt. This information aids the model in adapting to the specific task and generating a response accordingly. It is referred to as "single-shot" because only one prompt is utilized to guide the model's behavior. Through this approach, the model can execute tasks with higher accuracy and context compared to zero-shot.</p>
                <p className="block p-4 m-4 bg-sky-100 rounded-md dark:bg-sky-950">A limited amount of task-specific information or examples are given to guide the model.</p>

                <h3 id="shot-few"><span className="text-header-gradient">Few shot</span> prompting</h3>
                <p><strong className="dark:text-white">Few Shot Prompting</strong> is akin to single-shot prompting but involves providing the model with a few additional examples or context. Rather than just one example, the model is given a small number of examples to learn from. This extra context aids the model in better generalization and making more informed predictions for the given task. Few-shot prompting enables the model to adapt and perform effectively on tasks with limited training data.</p>
                <p className="block p-4 m-4 bg-sky-100 rounded-md dark:bg-sky-950">A small number of task-specific examples are provided to improve the model's understanding and generalization for the task.</p>

                <div className={"py-8 block"}>
                <img
                    src={require("../../assets/img/prompt-techniques/zero-single-few-shot-white.png")} alt="Zero, Single, Few shot prompting" className={"mb-8 mx-auto hidden dark:block"} />

                <img
                    src={require("../../assets/img/prompt-techniques/zero-single-few-shot.png")} alt="Zero, Single, Few shot prompting" className={"mb-8 mx-auto block dark:hidden"} />
                </div>

                <h4 id="shot-why-important">Why is this important?</h4>
                <p>Understanding the distinction between zero-shot, single-shot, and few-shot prompting is crucial when utilizing language models like ChatGPT. Doing so enables you to effectively harness the model's capabilities and achieve desired results for specific tasks. Each of these prompting techniques possesses its strengths and limitations, and knowing their appropriate usage can significantly impact the model's performance and the quality of generated responses. Familiarity with these differences and how to apply them will substantially reduce the number of prompts required to achieve your goal (g - goal-driven) and streamline the process of obtaining desired results through interactive prompting (i). Let's delve into the significance of each technique.</p>

                <p><strong>Zero-shot prompting:</strong></p>
                <ul className="list-disc pl-5">
                  <li>Importance: Zero-shot prompting is useful when you want to get quick and general answers to questions or perform tasks without fine-tuning the model on your specific data. It allows you to explore the model's pre-existing knowledge and capabilities.</li>
                </ul>


                <p><strong>Single-shot prompting:</strong></p>
                <ul className="list-disc pl-5">
                  <li>Importance: Single-shot prompting can be useful when you want to customize the model's behavior for specific tasks without going through an extensive fine-tuning process. It can help you fine-tune the model for your specific use case while avoiding the need for large-scale datasets.</li>
                </ul>

                <p><strong>Few-shot prompting:</strong></p>
                <ul className="list-disc pl-5">
                  <li>Importance: Few-shot prompting is valuable when you have a limited amount of task-specific data but still want the model to perform well on the target task. It helps the model grasp the patterns and understand the context from a few examples, making it more adaptable to new scenarios.</li>
                </ul>

                <p>Knowing when and how to apply these prompting techniques is essential because:</p>
                <ol className="list-decimal pl-5">
                  <li><strong>Efficiency</strong>: Zero-shot prompting is quick and convenient, but it might not always provide the most accurate or specific responses. On the other hand, single-shot and few-shot prompting require more effort in fine-tuning, but they can yield better results for your specific use cases.</li>
                  <li><strong>Customization</strong>: Single-shot and few-shot prompting allow you to tailor the model to your specific tasks or domain. They enable you to create a more specialized AI assistant that aligns better with your needs.</li>
                  <li><strong>Data availability</strong>: Depending on the availability of training data, you can choose the most suitable prompting approach. If you have a large dataset, you might consider fine-tuning with few-shot prompting. For niche tasks with limited data or when you need quick and efficient adaptation without extensive training, single-shot prompting might be more appropriate.</li>
                </ol>

                <p>The choice between these approaches depends on the availability of data, the complexity of the task, and the available resources.</p>
                <p>In summary, comprehending the distinctions between zero-shot, single-shot, and few-shot prompting empowers you to utilize language models effectively, achieve improved performance on tasks, and develop more customized and specialized AI applications. This all leads to the "<Link to="/documentation/framework#craft-c" className="text-blue-500 text-bold">Context (C)</Link>" of the framework. If your goal is to generate ideas, the question becomes how much context you should provide. However, if you seek more specific answers to your questions, the context becomes essential in attaining your desired goal. Therefore, before sending requests, consider Zero, Single, and Few-shot prompting as a meter to gauge how much context you should provide to reach your goal. Also take into account if the data you're request is available in the model.</p>

                <hr />

                <h2 id="chain-of-thought-prompting"><span className="text-header-gradient">Chain of Thought (CoT)</span> prompting</h2>
                <p>Remember the good old times at school when we had to explain how we arrived at the conclusion of our math solutions? This approach also works remarkably well for Prompt Engineering and is called "Chain of Thought (CoT) prompting."</p>
                <p>Chain of Thought prompting, is a technique used in working with large language models like GPT-3.5/4, where you break down a complex question or problem into a series of steps or a logical chain of thought. Instead of directly asking the model to generate a final answer, you instruct it to go through a reasoning process and provide a step-by-step explanation or solution.</p>
                <p>The idea behind chain prompting is to guide the model through the thought process you want it to follow. By doing so, you can often get more accurate and detailed responses, especially for complex or multi-step problems. It encourages the model to think through the problem systematically, similar to how a human would explain their thought process when solving a problem.</p>
                  <p>Imagine the example below. If you simply ask the model to provide an answer, you might receive an incorrect output as it's merely "predicting" the answer.</p>
                <div style={{"maxWidth": "600px", "margin": "40px auto"}}><p><img
                    src={require("../../assets/img/framework/cot-example-one.png")} alt="CoT - Example one (wrong)"/></p></div>

                <p>However, if we apply the same method as we did in high school when solving math problems, where we had to provide a reasoning process, and then ask the model to do the same, you'll discover that the answer differs and, in this case, is correct:</p>

                <div style={{"maxWidth": "600px", "margin": "40px auto"}}><p><img
                    src={require("../../assets/img/framework/cot-example-two.png")} alt="CoT - Example two (correct)"/></p></div>

                <p>By going through the reasoning process first, it helps ensure that the model reaches the correct final answer. This approach not only improves the accuracy of the response but also provides a clearer understanding of the thought process behind it. Incorporating this method as part of your "<Link to="/documentation/framework#craft-t" className="text-blue-500 text-bold">task - (t)</Link>" instruction following the Crafting AI Prompts Framework can lead to more reliable and informative outcomes.</p>

                <hr />

                <h2 id="emotional-prompting"><span className="text-header-gradient">Emotional</span> prompting</h2>
                <p>Large language models are potentially capable of comprehending and reacting to emotional signals.
                  Studies have shown that when emotional expressions are employed in interactions with AI chatbots, these models tend to
                  generate better results. This approach is known as "Emotional Prompting," a method that enhances the effectiveness of the
                  responses generated by your prompts. By indicating the significance of a <Link to="/documentation/framework#craft-t" className="text-blue-500 text-bold">task - (t)</Link> to your goals expressing urgency,
                  or playfully suggesting a "tip" for the model, you can potentially improve the quality of the interaction.</p>

                <h3>Examples</h3>
                <p>Inject urgency:</p>
                <p><span className="block p-4 m-4 bg-gray-100 rounded-md dark:bg-gray-950">... This is very important to my career ....</span></p>
                <p><span className="block p-4 m-4 bg-gray-100 rounded-md dark:bg-gray-950">... You'd better be sure ....</span></p>
                <p>Inject urgency, and speed:</p>
                <p><span className="block p-4 m-4 bg-gray-100 rounded-md dark:bg-gray-950">... It’s an emergency! ....</span></p>
                <p>Use positive reinforcement:</p>
                <p><span className="block p-4 m-4 bg-gray-100 rounded-md dark:bg-gray-950">... I know you’ll do great! ....</span></p>

                <h3>Research papers</h3>
                <p>The below buttons will redirect you to external links of papers that support this technique.</p>
                <BaseButton url={"https://arxiv.org/pdf/2307.11760.pdf"} targetBlank={true} styles="max-w-full px-8 py-4 bg-color[#3D5A6C] border border-[#0c66ee] dark:text-white">
                  Large Language Models Understand <br />and Can Be Enhanced by Emotional Stimuli
                </BaseButton>

                <hr />

                <p>Having mastered various prompt engineering techniques, it's crucial to be aware of potential risks. In the next chapter, we will explore prompt injections, helping you understand the vulnerabilities and how to safeguard your AI interactions.</p>
                <BaseButton url={"/documentation/prompt-injections#prompt-injections"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                  Next: Prompt Injections
                </BaseButton>
              </div>
        </div>
      </section>
  );
}

export default PromptTechniquesDocumentation;
