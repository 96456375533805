import React, {useEffect} from "react";
// Layouts
import DefaultLayout from "../layouts/default";
// Components
import HeaderBanner from "../components/sections/HeaderBanner";
import BaseButton from "../components/base/Button";

function Oops() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Whoops!"
  }, []);

  return (
    <div className="Blogs dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Whoops!"
                      buttons={[
                        {title: "Go home!", url: "/"}
                      ]}
        />

        <section className="relative flex max-w-full sm:mx-6 shadow sm:rounded-2xl overflow-hidden dark:bg-slate-900 dark:text-white">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 text-center dark:text-white"
          data-aos="fade-up">
            <h3 id="blogs">
              <span className="text-header-gradient">Whoops!</span> Something went wrong!
            </h3>
            <div className={"mb-8"}>
            <p>It seems like you tried to access a page that doesn't exist.</p>

            </div>
            <BaseButton url={"/"} styles="max-w-full px-8 py-4 mr-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
              Go back to the main page
            </BaseButton>
          </div>
        </section>
      </DefaultLayout>
    </div>
  );
}

export default Oops;
