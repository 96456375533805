// Components
import React, {useEffect} from "react";
import DocumentationNav from "./DocumentationNav";

function PromptArchitectureDocumentation() {
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Prompt Architecture"
    }, []);

    return (
        <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="prompt-techniques">
            <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
                <DocumentationNav page="PromptArchitecture" />

                <div className={"col-span-12 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section lg:col-span-9"}
                     id="prompt-architecture"
                     data-aos="fade-up"
                     data-aos-delay="100">
                    <h1 className="text-bold">Prompt <span className="text-header-gradient">Architecture</span></h1>
                    <p>Over the past year, discussions around prompting have revealed a common challenge: prompts are becoming excessively long, sometimes exceeding 1,000 words. This poses sustainability and manageability issues over time. This guide addresses these concerns by providing a structured approach to prompt creation and management.</p>
                    <p>You have learned to write prompts like “Acting as a [role], perform [task], in [format requirements], use [register/writing style] and use this context: [context].” This is great for writing quick prompts when you need fast results, but it becomes less effective when you want to share prompts within your organization. Why? The prompt is not structured, is crafted for a specific purpose, and is hard to extract.</p>
                    <p>In the Crafting AI Prompts Framework, "components" are mentioned. This involves using each letter of the framework as a component, which is more suitable for sharing and building a Prompt Library.</p>

                    <h2 id="prompt-libraries-overview">What is a <span className="text-header-gradient">Prompt library</span>?</h2>
                    <p>In short, it's a database of your prompts, presented in a user-friendly interface. This setup allows you to easily search through and select prompts, making it easy to copy, paste, and utilize the prompt you need.</p>
                    <p>Many organizations use this method to share prompts. This way, if you create a valuable prompt, others within the organization can use it, so they don’t have to write the complete prompt themselves. This is especially beneficial for large prompts.</p>
                    <p>There are quite a few tools on the market to help you with this, including version control, testing options, and user/cost management. Testing prompts can be costly, so gaining insights into where resources are spent is useful. These tools can help accelerate your prompting activities.
                        However, most of them lack one important feature: components.</p>

                    <h2 id="software-engineering-perspective">Learning from <span className="text-header-gradient">Software Engineering</span></h2>
                    <p>These issues were tackled long ago in Software Engineering. In its early days, we encountered files bursting with lines of code. Nowadays, we embrace brevity, clarity, and sound architecture. Why not adopt these principles from the outset?</p>
                    <p>Consider this: when you craft a prompt, there's a high likelihood that components of that prompt can be repurposed. If we examine the Crafting AI Prompts Framework, nearly every building block can potentially be reused for other prompts, except perhaps the Context section (due to its specificity).</p>
                    <p>Now, imagine formatting the output as a table, equipped with unique IDs. This format could seamlessly align with numerous other prompts you draft in the future. As a result, you might find yourself repeatedly constructing the same frameworks for your prompts, which is not the ideal approach!</p>
                    <p>Or consider the register within your organization. If you clearly define it in one component and reuse it within other prompts, all outputs (e.g., for your social media content) will have the same writing style. This approach ensures consistency and coherence across various platforms, making your brand voice recognizable and reliable. By standardizing your format, you can save time and maintain quality, enhancing your overall communication strategy.</p>
                    <p>Additionally, reusing a defined format streamlines the creation process, allowing for easier collaboration among team members. This uniformity not only helps in brand recognition but also improves the efficiency of your content generation process.</p>

                    <h2 id="prompt-component-library">Prompt <span className="text-header-gradient">component</span> library</h2>
                    <p>So, let’s move away from traditional “Prompt Libraries” and make them more maintainable by enhancing them to “Prompt Component Libraries.”
                        Revisiting the Crafting AI Prompts Framework, the most efficient approach would be to treat the building blocks from the framework as "components." These can then be cataloged in a Prompt Component Library.</p>
                    <p>For instance, consider the "table format with unique IDs" scenario. This could be a prompt component housed in the "Format" section of the Prompt Component Library.
                        Or the description of your register/writing style could be a prompt component housed in the “Register” section of the Prompt Component Library.</p>
                    <p>By adopting this method, you're cultivating a library of "components" that can be seamlessly integrated into subsequent Prompt Recipes.</p>

                    <div style={{"maxWidth": "300px", "margin": "40px auto"}}><p><img
                        src={require("../../assets/img/prompt-architecture/component-library.png")} alt="Component Library"/></p></div>

                    <p>If you also label each component with the model it was tested on and enhance it with other useful information, these components can be tested independently. This ensures that each component is up-to-date and ready to use. If new model updates break a component (e.g., the table format displays as plain text or in a code block instead of a real table), you can simply update the table component within your Format section. Consequently, all recipes that use this component will be updated with the latest version!</p>

                    <h2 id="prompt-recipes">Prompt <span className="text-header-gradient">recipes</span></h2>
                    <p>In Prompt Recipes, you combine the various parts from the Prompt Components to create the desired prompt. For instance, if you're crafting a LinkedIn post and want to incorporate specific hashtags, use emoticons instead of bullet points, and so on, you'd fetch those "Prompt Components" from the "Prompt Component Library" and incorporate them into your Prompt Recipe.</p>
                    <p>But that's just the beginning! As you construct a prompt, you'd likely want to append more details to it. This might include information like the model it was tested on (and its version), its creation date, the creator's name, and any recipe parameters that need to be inputted (more on this later). Additionally, a descriptive title and summary would be handy for easy retrieval later. You might also want to note requirements like the availability of specific plugins, especially if using platforms like ChatGPT or if any integration parameters were set to the API (for example, at OpenAI).</p>

                    <p>Additionally, you might want to incorporate "variables" or "parameters." In such cases, it's imperative to include these parameters in the Recipe. This ensures users are aware they must input these parameters before utilizing the prompt recipe.</p>
                    <p>For example, a variable could be the context of the prompt, such as specifying the topic of a LinkedIn post. This way, this part of the recipe is "variable," while all other components are sourced from the Component Library to maintain consistency in the output. This approach allows for flexibility in the prompt's application while ensuring uniformity in style and structure.</p>

                        <p>Lastly, if you're employing any API parameters (like adjusting the temperature, max_tokens, etc.), it's essential to include those details in the Prompt Recipe. This ensures that another user can replicate the exact output you achieved, maintaining the validity of your tests.</p>

                    <div style={{"maxWidth": "600px", "margin": "40px auto"}}><p><img
                        src={require("../../assets/img/prompt-architecture/prompt-recipes.png")} alt="Prompt recipes" /></p></div>

                    <p>With the recipe and the component library established, how does the final product appear?</p>

                    <div style={{"maxWidth": "500px", "margin": "40px auto"}}><p><img
                        src={require("../../assets/img/prompt-architecture/recipes-integration.png")} alt="Prompt Recipes integration"/></p></div>

                    <p>This illustrates how I've integrated various components into recipes. It would be even more advantageous if we had tools allowing for the simple drag-and-drop of these components into our recipes. In essence, crafting recipes would resemble a "no-code" or "no-prompt" platform for users, assisted by Prompt Engineers who add new components to the Component Library for users to use. Everything is readily available; the task is to assemble it cohesively within your Prompt Recipe.</p>
                    <p>This method truly adds value. The value isn't solely in the completed prompt but in its individual components. So, why not deconstruct them into reusable components, mirroring practices in Software Engineering?</p>
                    <p>Also, consider the numerous new opportunities that arise, such as labeling the components and identifying the strategies used, such as Chain-of-Thought Prompting, Set of Mark Prompting, and so on.</p>

                    <h2 id="prompt-workflows"><span className="text-header-gradient">Workflow</span> prompting</h2>
                    <p>This introduces a fascinating perspective. In other blogs, I briefly discussed Zapier, a powerful automation tool that connects different applications and services to automate repetitive tasks. I believe its flow aligns perfectly with prompting! Essentially, you initiate a prompt, execute a specific action, and upon completion, potentially trigger subsequent prompts. This concept can be termed "workflow prompting." Instead of automating just one specific aspect of your workflow using prompts, you aim to automate the entire workflow!</p>
                    <p>To delve deeper into this concept: You initiate a task (a workflow task). Upon its completion, it instigates the succeeding task. Each task is aligned with a specific Prompt Recipe that it activates. Prompt Recipes, in turn, include various Prompt Components, making the process complete and cohesive.</p>
                    <p>For instance, a workflow task might be: "Create user stories and upload to Jira." Within the prompt Recipe, user stories are generated utilizing a tool like Microsoft Copilot (please keep the N-Non disclosure in mind!). It's essential to use tools that do not expose your company data and are GDPR compliant. Similarly, when leveraging tools like Zapier to automate the transfer to Jira, ensure these tools also adhere to non-disclosure and GDPR compliance. Once this is accomplished, the next workflow task is set in motion.</p>
                    <p>The beauty of it is that you can design a default workflow that's accessible to everyone in your organization. Additionally, users have the flexibility to customize and add their elements to the workflow, ensuring it aligns with their specific needs.</p>

                    <div style={{"maxWidth": "600px", "margin": "40px auto"}}><p><img
                        src={require("../../assets/img/prompt-architecture/workflows.png")} alt="Prompt Workflows" /></p></div>

                    <h2 id="framework-integration">Crafting AI prompts framework <span className="text-header-gradient">integration</span></h2>
                    <p>The entirety of this framework aligns seamlessly with the aforementioned strategy. Every component from the Crafting phase can be found in the Prompt Component Library. The Validation Phase components are vital and should be addressed individually:</p>
                    <ul className="list-disc pl-5">
                        <li>Workflows ought to be interactive (I), enabling them to consider input from preceding steps as they move forward.</li>
                        <li>Prompt Recipes should be goal-driven (G) to ensure they maintain a singular focus and can be integrated effectively into the prompt workflow.</li>
                        <li>Crucially, no component should contain any confidential or personal data (N). Thus, within the prompts component library, provisions for non-disclosure must be adhered to (N).</li>
                        <li>Lastly, prompt recipes come with versions (history), model information, and all other needs to inform the user what has been used to achieve the desired goal (G) to facilitate the Adapt and Improve (AI) process.</li>
                    </ul>

                    <p>The (G) and (AI) principles will also prove invaluable when using tools to test and manage your prompts. As LLMs (Large Language Models) evolve, it’s possible that outcomes may change. By having all the information available in the recipes, including the models they were tested on, it’s always clear which model gives the correct output. This transparency allows for easy updates to newer versions, ensuring consistent and reliable results.</p>
                    <p>By adopting a Prompt Component Library and integrating it with structured Prompt Recipes, organizations can create a sustainable and maintainable prompt architecture. This approach not only simplifies prompt management but also aligns with best practices from software engineering, enhancing overall efficiency and effectiveness.</p>
                </div>
            </div>
        </section>
    );
}

export default PromptArchitectureDocumentation;
