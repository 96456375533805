import BaseSection from "../base/Section";
import BaseButton from "../base/Button";
import React from "react";


interface HeaderBannerProps {
    title: string,
    buttons?: {title:string, url:string}[],
    heightStyle?: string
}
const HeaderBanner: React.FC<HeaderBannerProps> = ({ title, buttons, heightStyle }) => {
    return(
        <section id="hero" className="w-full pb-20 dark:bg-slate-900">
            <div className={`absolute top-0 left-0 w-full cover-gradient-2 sm:cover-gradient dark:bg-gradient-to-b dark:from-gray-900 dark:to-gray-950 lg:h-[530px] ` + (heightStyle ? heightStyle : `h-[580px]`) }></div>            <BaseSection>
                <div className="col-span-12 lg:col-span-7 lg:pt-6 pt-2 mt-12 xl:mt-10 space-y-4 sm:space-y-6 px-6 text-center sm:text-left">
                    <h1
                        data-aos="fade-right"
                        data-aos-once="true"
                        className="text-[2.5rem] sm:text-5xl xl:text-6xl font-bold leading-tight capitalize sm:pr-8 xl:pr-10 dark:text-white"
                    >
                        <span className="text-header-gradient">Crafting AI</span>{" "} Prompts {title}
                    </h1>
                    <p
                        data-aos="fade-up"
                        data-aos-once="true"
                        data-aos-delay="300"
                        className="paragraph hidden sm:block dark:text-white"
                    >
                        Master the Crafting AI Prompts framework, and become a Prompt Champion!
                    </p>
                    <div
                        data-aos="fade-up"
                        data-aos-once="true"
                        data-aos-delay="700"
                        className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-2"
                    >
                        {buttons?.map((item, key) =>
                            <BaseButton url={item.url} key={key} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                                {item.title}
                            </BaseButton>
                        )}
                    </div>
                </div>
                <div className="hidden lg:block col-span-12 lg:col-span-5">
                    <div className="w-full">
                        <img
                            data-aos="fade-left"
                            data-aos-once="true"
                            src={require("../../assets/img/logo/logo.png")}
                            className="-mt-4 pt-7"
                            style={{"maxWidth": "400px"}}
                            alt="Logo Crafting AI Prompts Framework"
                        />
                    </div>
                </div>
                <img
                    data-aos="fade-up"
                    data-aos-delay="250"
                    src={require("../../assets/img/pattern/ellipse-1.png")}
                    className="hidden sm:block absolute top-6 xl:top-6 left-16 xl:left-16 w-6"
                    alt=""
                />
                <img
                    data-aos="fade-up"
                    data-aos-delay="270"
                    src={require("../../assets/img/pattern/ellipse-2.png")}
                    className="hidden sm:block absolute top-6 sm:top-6 right-64 sm:right-96 xl:right-[32rem] w-6"
                    alt=""
                />
                <img
                    data-aos="fade-up"
                    data-aos-delay="290"
                    src={require("../../assets/img/pattern/ellipse-3.png")}
                    className="hidden sm:block absolute bottom-56 right-24 w-6"
                    alt=""
                />
                <img
                    data-aos="fade-up"
                    data-aos-delay="300"
                    src={require("../../assets/img/pattern/star.png")}
                    className="hidden sm:block absolute top-12 sm:top-32 right-16 lg:right-0 lg:left-[30rem] w-8"
                    alt=""
                />
            </BaseSection>
        </section>
    );
}

export default HeaderBanner;