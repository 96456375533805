import React, { useEffect, useRef, useState } from "react";

/*
* For this file I added the classes (that are variable) in the tailwind.config.js safelist.
* */


export enum AlertType {
    SUCCESS = "green",
    INFO = "sky",
    FAIL = "red",
}

interface alertProps {
    text: string;
    type: AlertType;
}

const AlertBoxTimed: React.FC<alertProps> = ({ text, type }) => {
    const [successAlert, setSuccessAlert] = useState(false);
    const [animation, setAnimation] = useState(true);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        setSuccessAlert(true);

        // Trigger re-animation by toggling the animation state
        setAnimation(false);
        setTimeout(() => setAnimation(true), 0);

        // Clear the existing timeout if it exists
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Set a new timeout
        timeoutRef.current = setTimeout(() => {
            setSuccessAlert(false);
        }, 10000);

        // Cleanup timeout on component unmount or text change
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [text]);

    return successAlert && text !== "" ? (
        <div id="alert-border-3" role="alert">
            <div className={`w-[100%] h-1 animate-fade-border ${animation ? 'animate' : ''} bg-${type}-300 dark:bg-${type}-800`}></div>
            <div className={`flex items-center p-4 mb-4 dark:bg-gray-800 dark:text-${type}-400 text-${type}-800 bg-${type}-50`}>
                <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <div className="ms-3 text-sm font-medium">
                    {text}
                </div>
                <button type="button"
                        className={`ms-auto -mx-1.5 -my-1.5 bg-${type}-50 text-${type}-500 rounded-lg focus:ring-2 focus:ring-${type}-400 p-1.5 hover:bg-${type}-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-${type}-400 dark:hover:bg-gray-700`}
                        data-dismiss-target="#alert-border-3" aria-label="Close" onClick={() => setSuccessAlert(false)}>
                    <span className="sr-only">Dismiss</span>
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                </button>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default AlertBoxTimed;