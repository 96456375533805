// Components
import React, {useEffect, useState} from "react";
import DocumentationNav from "./DocumentationNav";
import BaseButton from "../../components/base/Button";
import PromptEngineeringCatLabel from "../../components/labels/PromptEngineeringCatLabel";
import { ReactComponent as PECommonChallenges } from "../../assets/img/prompt-engineering/pe-common-challenges.svg";


function PromptEngineeringDocumentation() {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const checkDarkMode = () => {
            setIsDarkMode(document.getElementById('root')?.classList.contains('dark') || false);
        };

        // Check dark mode on mount and whenever the 'class' attribute of the root element changes
        checkDarkMode();
        const observer = new MutationObserver(checkDarkMode);
        const rootElement = document.getElementById('root');
        if (rootElement) {
            observer.observe(rootElement, { attributes: true, attributeFilter: ['class'] });
        }

        // Clean up the observer when the component unmounts
        return () => observer.disconnect();
    }, []);

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Prompt Engineering"
  }, []);

  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="prompt-engineering">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="PromptEngineering" />

              <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="prompt-engineering-pe"
                   data-aos="fade-up"
                   data-aos-delay="100">
                <h1 className="text-bold"><span className="text-header-gradient">Prompt</span> Engineering</h1>
                  <p>Prompt Engineering is an emerging discipline focused on crafting and refining prompts to maximize the effectiveness of language models (LMs), diffusion models, and other advanced technologies across various applications. Prompt Engineering enhances our understanding of the capabilities and constraints of large language models (LLMs) and is employed to guide the capabilities of LLMs and diffusion models in handling diverse tasks, from complex question answering to nuanced arithmetic reasoning.</p>
                  <p>Proper Prompt Engineering, can tackle a few common challenges, such as model bias, unpredictability, lack of real-world knowledge and language and cultural nuances.</p>

                  <div className={"py-8 block"}>
                  {
                      isDarkMode ?
                        <PECommonChallenges className="max-w-xl m-auto stroke-white svg-stroke-dark-light" />
                        : <PECommonChallenges className="max-w-xl m-auto stroke-gray svg-stroke-dark-light" />
                  }
                  </div>

                  <p>Prompt Engineering branches into two specific areas: User Prompt Engineering and <strong><a href={"/documentation/prompt-engineering#system-prompt-engineering"} className="text-bold underline dark:text-white">System Prompt Engineering</a></strong> (SPE).
                      While System Prompts have their own unique challenges, the focus of this guide is on User Prompts, which are further divided into two categories: <strong><a href={"/documentation/prompt-engineering#interactive-prompt-engineering"} className="text-bold underline dark:text-white">Interactive Prompt Engineering</a></strong> (IPE) and <strong><a href={"/documentation/prompt-engineering#interactive-visual-prompt-engineering"} className="text-bold underline dark:text-white">Interactive Visual Prompt Engineering</a></strong> (IVPE).
                      Please note that the term "Interactive" in IPE and IVPE refers to the user-facing aspect of the prompts, not the system's interactivity.
                      Also note that there could be much more categories in the "User Prompt Engineering" part, think about the "Interactive Audio Prompt Engineering" for example. But for now, we will focus on IPE and IVPE.
                  </p>
                      <p>IPE centers on creating prompts that users input via interfaces, facilitating direct and intuitive interactions with LLMs, enhancing real-time responsiveness and engagement.
                      Conversely, SPE focuses on developing system-level prompts that guide LLM and diffusion model responses more accurately and integrate advanced technologies and ethical standards.
                      Adjacent to IPE is <strong><a href={"/documentation/prompt-engineering#interactive-visual-prompt-engineering"} className="text-bold underline dark:text-white">Interactive Visual Prompt Engineering</a></strong> (IVPE), which specializes in the output aspect of diffusion models, focusing on generating visual content such as images and videos in response to prompts.
                  </p>

                  <div className={"py-8 block"}>
                  {
                      isDarkMode ? <img
                              src={require("../../assets/img/prompt-engineering/pe-differences1.png")} alt="Prompt Engineering differences" className={"m-auto dark-image-textual"} />
                          : <img
                              src={require("../../assets/img/prompt-engineering/pe-differences1.png")} alt="Prompt Engineering differences" className={"m-auto light-image-textual"} />
                  }
                  </div>

                  <p>Clearly communicating about whether one is discussing UPE (IPE, IVPE) or SPE helps in setting appropriate expectations, aligning development goals, and ensuring that discussions about potential improvements or implementations are precise and meaningful. This clarity is essential not only for developers and engineers who design and refine these systems but also for stakeholders and end-users who rely on their effectiveness and reliability in real-world applications.</p>

                  <p>Throughout this guide, we distinguish between IPE, IVPE and SPE if necessary, which are labeled accordingly to enhance clarity and understanding. Here’s what each label represents:</p>

                  <table className={"border-separate border-spacing-2"}>
                      <tbody>
                          <tr>
                              <td><PromptEngineeringCatLabel category="IPE" /></td>
                              <td>Indicates Interactive Prompt Engineering</td>
                          </tr>
                          <tr>
                              <td><PromptEngineeringCatLabel category="IVPE" /></td>
                              <td>Indicates Interactive Visual Prompt Engineering</td>
                          </tr>
                          <tr>
                              <td><PromptEngineeringCatLabel category="SPE" /></td>
                              <td>Indicates System Prompt Engineering</td>
                          </tr>
                      </tbody>
                  </table>

                <hr />

                <div className={"block flex col-2 mb-1"} id="interactive-prompt-engineering">
                    <h2 className={"mb-1"}><span className="text-header-gradient">Interactive</span> Prompt Engineering</h2>
                    <div className={"mt-3 ml-4"}>
                        <PromptEngineeringCatLabel category="IPE" />
                    </div>
                </div>
                <p>Interactive Prompt Engineering (IPE) focuses on the user-facing aspect of LLM interactions. It centers around crafting prompts that users enter via an interface, optimizing the way these inputs are processed by LLM systems to generate immediate and pertinent textual responses. The primary goal of IPE is to facilitate effective communication with LLMs through natural language to generate textual output.</p>
                <p>IPE is crucial in applications where direct interaction with LLMs is integral. It is employed across a range of tools and platforms that depend on prompt responsiveness and the specificity of user inputs for optimal functionality. Prominent examples of IPE usage include platforms like ChatGPT and Microsoft Co-pilot, among others.</p>
                <p>In these applications, the quality of user inputs (prompts) significantly influences the AI’s output effectiveness, highlighting the importance of Prompt Engineering expertise in ensuring the system accurately interprets and responds to user intentions.
                    This facet of Prompt Engineering is essential for maintaining user satisfaction and enhancing the practical application of LLMs technologies in everyday scenarios.</p>
                  <p>A good approach for IPE, is using the Crafting AI Prompts Framework, which is designed to empower users in effectively utilizing Generative AI tools for various writing and engagement tasks while ensuring ethical and responsible use.</p>

                  <BaseButton url={"/documentation/framework#framework"} styles="max-w-full px-8 py-4 mr-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                      Crafting AI Prompts Framework - IPE section
                  </BaseButton>

                <hr />

                  <div className={"block flex col-2 mb-1"} id="interactive-visual-prompt-engineering">
                      <h2 className={"mb-1"}><span className="text-header-gradient">Interactive Visual</span> Prompt Engineering</h2>
                      <div className={"mt-3 ml-4"}>
                          <PromptEngineeringCatLabel category="IVPE" />
                      </div>
                  </div>
                  <p>While Interactive Prompt Engineering (IPE) primarily concentrates on LLMs and textual outputs, this next phase introduces a distinct visual aspect to the interaction—specifically, the generation of images and videos in response to user prompts.
                      This advancement transitions the focus from text-based to visual representations through the use of diffusion models.
                      When users provide prompts intended for visual outputs, the system utilizes cutting-edge image and video generation technologies to create dynamic, contextually relevant visuals.</p>

                  <p>Interactive Visual Prompt Engineering (IVPE) is crucial in applications where generating visual content through diffusion models is integral. It is employed across a range of tools and platforms that depend on prompt responsiveness and the specificity of user inputs for optimal functionality. Prominent examples of IVPE usage include platforms like Midjourney and DALL-E 3, among others.</p>

                      <p>A good approach for IVPE, is using the Crafting AI Prompts Framework (IVPE), which is designed to empower users in effectively utilizing Generative AI tools for various visual tasks such as generating images while ensuring ethical and responsible use.</p>
                  <BaseButton url={"/documentation/framework/visual#framework"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                      Crafting AI Prompts Framework - IVPE section
                  </BaseButton>

                  <hr />

                <div className={"block flex col-2 mb-1"} id="system-prompt-engineering">
                  <h2 className={"mb-1"}><span className="text-header-gradient">System</span> Prompt Engineering</h2>
                    <div className={"mt-3 ml-4"}>
                        <PromptEngineeringCatLabel category="SPE" />
                    </div>
                </div>
                <p>System Prompt Engineering (SPE) focuses on the development and refinement of system-level prompts within LLM/diffusion models applications.
                      This aspect of Prompt Engineering involves crafting sophisticated backend prompts that guide LLM responses, incorporating advanced technologies such as Retrieval-Augmented Generation (RAG), Graph RAG, and implementing guardrails to ensure the LLM operates within ethical and factual boundaries.</p>
                <p>SPE is essential for applications that require a high level of precision and contextual understanding from LLM responses.
                      It plays a crucial role in settings where LLMs must navigate complex information structures or adhere to specific regulatory and ethical standards.</p>
                <p>By focusing on the systematic development of these internal prompts, System Prompt Engineering ensures that LLM applications not only respond accurately to user queries but do so in a way that is aligned with the intended operational frameworks and ethical guidelines.
                      This specialization within Prompt Engineering enhances the reliability, safety, and effectiveness of LLM systems across various domains.</p>

                  <hr />

                  <p>Now that you have a solid understanding of Prompt Engineering, it's time to explore the Crafting AI Prompts Framework. To get started, make sure to follow the appropriate guide for your needs.</p>
                  <BaseButton url={"/documentation/framework/introduction#introduction"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                      Next: Introducing the framework
                  </BaseButton>

              </div>
        </div>
      </section>
  );
}

export default PromptEngineeringDocumentation;
