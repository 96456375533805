import AuthCard from '../../components/dashboard/AuthCard'
import AuthValidationErrors from '../../components/dashboard/AuthValidationErrors'
import Button from '../../components/dashboard/Button'
import GuestLayout from '../../components/dashboard/Layouts/GuestLayout'
import Input from '../../components/dashboard/Input'
import Label from '../../components/dashboard/Label'
import { useAuth } from '../../hooks/auth'
import React, {useEffect, useState} from 'react'
import {Link, NavLink} from 'react-router-dom';
import {ReactComponent as Logo} from "../../assets/img/logo/crafting-ai-logo.svg";

const Register = () => {
    const { register } = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/dashboard'
    })

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password_confirmation, setPasswordConfirmation] = useState('')
    const [errors, setErrors] = useState([])
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const submitForm = event => {
        event.preventDefault()
        register({ name, email, password, password_confirmation, setErrors })
    }

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Register"
    }, []);

    return (
        <GuestLayout>
            <AuthCard
                logo={
                    <Link to="/">
                        <Logo />
                    </Link>
                }>
                {/* Validation Errors */}
                <AuthValidationErrors className="mb-4" errors={errors} />
                <form onSubmit={submitForm}>
                    {/* Name */}
                    <div>
                        <Label htmlFor="name">Name (Also be shown on your certificates)</Label>
                        <Input
                            id="name"
                            type="text"
                            value={name}
                            className="block mt-1 w-full p-2"
                            onChange={event => setName(event.target.value)}
                            required
                            autoFocus
                        />
                    </div>
                    {/* Email Address */}
                    <div className="mt-4">
                        <Label htmlFor="email">Email</Label>
                        <Input
                            id="email"
                            type="email"
                            value={email}
                            className="block mt-1 w-full p-2"
                            onChange={event => setEmail(event.target.value)}
                            required
                        />
                    </div>
                    {/* Password */}
                    <div className="mt-4">
                        <Label htmlFor="password">Password</Label>
                        <Input
                            id="password"
                            type="password"
                            value={password}
                            className="block mt-1 w-full p-2"
                            onChange={event => setPassword(event.target.value)}
                            required
                            autoComplete="new-password"
                        />
                    </div>
                    {/* Confirm Password */}
                    <div className="mt-4">
                        <Label htmlFor="password_confirmation">
                            Confirm Password
                        </Label>
                        <Input
                            id="password_confirmation"
                            type="password"
                            value={password_confirmation}
                            className="block mt-1 w-full p-2"
                            onChange={event =>
                                setPasswordConfirmation(event.target.value)
                            }
                            required
                        />
                    </div>
                    {/* Terms */}
                    <div className="flex items-center mt-4">
                        <input
                            id="terms"
                            type="checkbox"
                            className="rounded border-gray-300 text-blue-500 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            onChange={() => setAcceptedTerms(!acceptedTerms)}
                        />
                        <label htmlFor="terms" className="ml-2 text-sm text-gray-600">
                            I agree to the <a href="/account/terms" className="text-blue-500">terms</a>
                        </label>
                    </div>
                    <div className="flex items-center justify-between mt-4">
                        <NavLink
                            to="/login"
                            className="underline text-sm text-gray-600 hover:text-gray-900"
                        >
                            Already registered?
                        </NavLink>
                        <Button className="ml-4" disabled={!acceptedTerms}>Register</Button>
                    </div>
                </form>
            </AuthCard>
        </GuestLayout>
    )
}

export default Register