// Components
import React, {useEffect} from "react";
import DocumentationNav from "./DocumentationNav";
import BaseButton from "../../components/base/Button";
import CustomGPTLeaking from "./injections/CustomGPTLeaking";

function PromptLeakingDocumentation() {
  // sessionStorage.setItem('handout-framework', "false"); // Set false, seems like it should be available.
  // sessionStorage.setItem('handout-techniques', "false"); // Set false, seems like it should be available.

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Prompt Leaking"
  }, []);

  return (
      <>
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="prompt-leaking">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="PromptLeaking" />

              <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="prompt-leaking-overview"
                   data-aos="fade-up"
                   data-aos-delay="100">

                <CustomGPTLeaking />

                  <BaseButton url={"/documentation/adversarial-prompting/prompt-jailbreak#prompt-jailbreak"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                      Next: Prompt Jailbreak
                  </BaseButton>
              </div>
        </div>
      </section>
  </>
  );
}

export default PromptLeakingDocumentation;
