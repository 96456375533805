// Layouts
import DefaultLayout from "../layouts/default";
// Components
import React, {useEffect, useRef} from "react";
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import {Outlet, useLocation} from "react-router-dom";

function Workshop() {
    const location = useLocation();
    const lastHash = useRef('');

    useEffect(() => {
        goToLocation(-130);
    }, [goToLocation]);

    // listen to location change using useEffect with location as dependency
    // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
    useEffect(() => {
        goToLocation(-20);
    }, [location]);

    function goToLocation(yOffset: number) {

        if (location.hash) {
            lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                const element = document.getElementById(lastHash.current);
                if (element) {
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                }

                lastHash.current = '';
            }, 50);
        }
    }

  return (
      <div className="Workshop dark:bg-slate-900 dark:text-white">
        <DefaultLayout>
          <HeaderBanner title="Workshop"
                        buttons={[{title: "Workshop", url: "/workshop#introduction"}]}
                        heightStyle={"h-[440px]"}
          />

          <Outlet />

          <ThreePhasesSection />

          <BackToTop sticky={true} />
        </DefaultLayout>
      </div>
  );
}

export default Workshop;
