import React, { useEffect, useRef } from 'react';
import TicketStatusEnum from "../../enums/TicketStatusEnum";
import TicketUpdate from "./TicketUpdates";

const ReplyTicketModal = ({ showReplyModal, setShowReplyModal, ticketList, currentTicketId, replyText, setReplyText, handleReplySubmit, status }) => {
    const scrollRef = useRef(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [showReplyModal]);

    if (!showReplyModal) {
        return null;
    }

    const currentTicket = ticketList.find(ticket => ticket.id === currentTicketId);
    const ticketUpdates = currentTicket?.updates || [];

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded shadow-lg w-full max-w-2xl max-h-[83.333vh] flex flex-col">
                <div className="p-6 flex-shrink-0">
                    <h2 className="text-xl font-bold mb-4">Reply to Ticket</h2>
                </div>
                <div ref={scrollRef} className="overflow-y-auto flex-grow px-6">
                    <div className="flex flex-col-reverse">
                        {ticketUpdates.slice().reverse().map((update, index) => (
                            <TicketUpdate key={index} update={update} />
                        ))}
                    </div>
                </div>
                <div className="p-6 flex-shrink-0">
                    {status === TicketStatusEnum.Closed ? (
                        <p className="text-red-500 text-sm mb-4">This ticket is closed. You cannot reply to it.</p>
                    ) : (
                        <textarea
                            value={replyText}
                            onChange={e => setReplyText(e.target.value)}
                            className="w-full h-32 p-2 border border-gray-300 rounded mb-4"
                            placeholder="Enter your reply here... If we can close the ticket, please let us know in your reply!"
                        />
                    )}
                    <div className="flex justify-end">
                        <button onClick={() => setShowReplyModal(false)} className="px-4 py-2 mr-2 text-gray-700 hover:text-gray-900">Close</button>
                        {status !== TicketStatusEnum.Closed && (
                            <button onClick={handleReplySubmit} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Submit</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReplyTicketModal;