// Components
import React, {useEffect} from "react";
import DocumentationNav from "./DocumentationNav";
import BaseButton from "../../components/base/Button";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import LinksEnum from "../../components/enums/LinksEnum";

function Introduction() {

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Introduction";
  }, []);

  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="llms">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="LargeLanguageModels" />

              <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="explain-llms"
                   data-aos="fade-up"
                   data-aos-delay="100">
                <h1 className="text-bold"><span className="text-header-gradient">Introduction</span></h1>
                  <p>Welcome to The Crafting AI Prompts Framework, your guide to mastering the art of prompt creation, whether you're using ChatGPT, Microsoft Co-pilot, Claude, or any other AI model. If you're wondering how to craft a prompt, generate prompts, or create maintainable and sustainable prompts, you're in the right place. Here, we dive into the details of crafting effective and creative prompts, exploring what Prompt Engineering is and discussing various techniques and strategies.</p>
                  <p>We also focus on creating prompts that are maintainable and sustainable, so you won't end up with databases full of unused prompts like many companies do. In the Architecture section, you'll learn how to set up your prompts responsibly, ensuring they are reusable and adaptable over time. With these insights, you'll be equipped to create and improve prompts that work across different AI models while maintaining long-term efficiency and effectiveness.</p>
                  <p>Before we can dive into these topics, it's important to understand the basics of Generative AI, which is the technology behind creating new content like text, images, or sounds. Large Language Models (LLMs), a key part of Generative AI, are based on vast datasets and complex algorithms that allow them to understand and generate human-like text. Additionally, we'll touch on Diffusion Models, another type of Generative AI, which are used in creating high-quality images and other media. This foundational knowledge will make it easier to grasp the concepts and techniques of Prompt Engineering.</p>
                  <p>In the next section, we will explain what LLMs are, providing you with the essential background needed to fully appreciate the subsequent discussions on Prompt Engineering and the framework we've created.</p>

                  <h2 id="what-is-an-llm"><span className="text-header-gradient">What is</span> an LLM?</h2>
                  <p>A Large Language Model (LLM) is a type of artificial intelligence designed to recognize and generate human-like text based on vast amounts of data. These models are trained using extensive datasets from books, articles, websites, and other text sources to learn language patterns, grammar, context, and semantics. LLMs can perform various tasks such as language translation, text summarization, question answering, and even creative writing. They leverage complex algorithms and deep learning techniques to predict and generate coherent and contextually appropriate text, making them powerful tools for natural language processing applications.</p>
                  <p>LLMs are the foundation behind many well-known Generative AI applications like ChatGPT, Microsoft Copilot, and Google Gemini. These tools are revolutionizing various aspects of our daily lives, and their impressive capabilities are likely what brought you to this guide. By understanding LLMs, you'll gain insights into the technology powering these applications and how to harness their full potential through effective Prompt Engineering.</p>

                  <h2 id="prediction-based"><span className="text-header-gradient">Prediction</span> based</h2>
                  <p>Before understanding how Prompt Engineering works, it's essential to grasp the concept of "predictions." Predictions are at the heart of how Large Language Models (LLMs) like ChatGPT function. LLMs are designed to predict the next token in a sequence based on the context provided by the previous tokens. Tokens can be words, parts of words, or even characters, depending on the specific model and its training. This process involves analyzing vast amounts of text data to learn language patterns and relationships between tokens. When you input a prompt, the model generates a response by predicting each subsequent token, creating coherent and contextually appropriate text.</p>
                  <p>Understanding that LLMs are prediction-based is crucial because it highlights that these models can occasionally make mistakes. Since they rely on probabilities derived from their training data, their predictions might not always be accurate or relevant. This inherent unpredictability underscores the importance of crafting precise and well-structured prompts to guide the model effectively.</p>
                  <p>For a deeper understanding of how predictions work and how LLMs operate, the below video from Code.org offers an excellent explanation. Watching this video will give you a solid foundation before diving into the Crafting AI Prompts Framework.</p>

                  <YoutubeEmbed embedId="X-AWdfSFCHQ" />
                  <p>If the video doesn't work, <a href={"https://www.youtube.com/watch?v=X-AWdfSFCHQ"} rel="noreferrer" target={"_blank"} title={"YOUTUBE: How Chatbots and Large Language Models work"} className={"text-sky-500"}>click here to go to Youtube</a>.</p>

                  <h2 id="token-based"><span className="text-header-gradient">Token-Based</span></h2>
                  <p>In Prompt Engineering, it's crucial to understand how tokens work. A great way to grasp what a token is, is by experimenting in OpenAI's Playground.
                      Recently, many models were tested with the question: "How many R's are there in the word 'strawberry'?" Interestingly, most models provided the incorrect answer (1 or 2).</p>
                  <img src={require("../../assets/img/prompt-introduction/chatgpt-strawberry-two-words.png")} alt="Strawberry two words in ChatGPT" className="w-full" />

                  <p>This is a perfect example of how tokens work. Let's break it down in OpenAI's Playground:<br />
                      First, let's tokenize the question: "How many R's are there in the word 'strawberry'?"</p>
                  <img src={require("../../assets/img/prompt-introduction/tokens-explained.png")} alt="Tokenize question" className="w-full" />
                  <p>At the bottom of the image, you can see that the question is highlighted in different colors. Each color represents a different token, making it clear where each token starts and ends, which helps illustrate how the model processes the text.</p>
                  <p>As shown, the question is tokenized into 11 tokens, with "strawberry" being considered as a single token. <br />
                      This means the model interprets "strawberry" as one entity, which can make answering the question challenging. Keep this in mind when crafting prompts.</p>
                  <p>In a large language model (LLM), a token is simply a number that represents a word, part of a word, or even a single character. Instead of working directly with text, the LLM breaks down the input into these tokens, which are then converted into numbers that the model can process. So, when we say "token," we’re really talking about a number that the model uses to understand and generate language.</p>
                  <img src={require("../../assets/img/prompt-introduction/tokens-to-numbers.png")} alt="Tokens to numbers" className="w-full" />
                  <p>In the image above, you can see how the text is broken down into numbers, which makes it easy to see how mistakes can happen along the way.</p>

                  <p>But if we change it, adding spaces or something else between "strawberry," each part gets its own token.</p>
                  <img src={require("../../assets/img/prompt-introduction/strawberry-with-spaces.png")} alt="Strawberry with spaces" className="w-full" />

                  <p>And if we use this modified text to ask a question in ChatGPT, we still get the correct results.</p>
                  <img src={require("../../assets/img/prompt-introduction/chatgpt-strawberry-with-spaces.png")} alt="ChatGPT Strawberry with spaces" className="w-full" />

                  <p>Understanding how tokens work is essential in Prompt Engineering, as it directly influences how language models interpret and respond to questions. By experimenting with tokenization, like in the example of "strawberry," we can see how subtle changes in text can impact the model's understanding. This knowledge empowers us to craft more precise prompts, ensuring we get the most accurate responses from the model. Ultimately, mastering tokens is key to effectively leveraging the full potential of large language models in various applications.</p>

                  <BaseButton url={"https://platform.openai.com/tokenizer"} styles="max-w-full px-8 py-4 mr-5 mb-5 border border-[#0c66ee]">
                      Try it yourself in the OpenAI Tokenizer Playground
                  </BaseButton>

                  <h2 id="diffusion-models">What are <span className="text-header-gradient">Diffusion Models</span>?</h2>
                  <p>Diffusion models are a type of Generative AI designed to create high-quality images and other media by gradually transforming simple, random noise into structured outputs. These models work through a process of iterative refinement, starting with a noisy image and slowly "denoising" it, step by step, until it resembles the desired output. The process is akin to reversing the natural diffusion process, where noise spreads out and disrupts an image; instead, the model learns to reconstruct the image from this noise, guided by patterns it has learned from vast datasets.</p>
                  <p>Diffusion models are particularly powerful in generating detailed and realistic images, making them an essential tool in areas like art generation, medical imaging, and video creation. They offer an alternative approach to traditional methods of image generation, allowing for more controlled and fine-tuned outputs. Just like LLMs are the backbone of text-based AI applications, diffusion models are becoming foundational in the realm of AI-driven visual content creation, opening up new possibilities for creative and technical fields alike. Understanding diffusion models adds another layer to your grasp of Generative AI, enhancing your ability to work with and create prompts that guide these models effectively.</p>
                      <hr />

                  <p>Now that you have a solid understanding of what Large Language Models (LLMs) and Diffusion Models are and how they work, we can move forward to the exciting world of Prompt Engineering. Ready to continue? Let's dive into the world of Prompt Engineering.</p>

                  <div className={"pt-5"}>
                      <BaseButton url={LinksEnum.PromptEngineeringAnchor} styles="max-w-full px-8 py-4 mr-5 mb-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                          Next: Prompt Engineering
                      </BaseButton>
                  </div>
              </div>
        </div>
      </section>
  );
}

export default Introduction;
