import React from "react";
import {ShieldCheck, GraduationCap} from 'lucide-react';

const TicketUpdate = ({ update }) => {
    return (
        <div className={`mb-4 p-4 rounded ${update.user.role >= 3 ? `bg-purple-100` : update.user.role === 2 ? `bg-red-100` : `bg-blue-100`}`}>
            <p className="text-blue-500 font-bold mb-2">
                <span className="flex items-center">
                    <span className="mr-2">{update.user.name}</span>
                    {update.user.role >= 3 ? <ShieldCheck className="text-yellow-800" size={20} /> : update.user.role === 2 ? <GraduationCap className="text-yellow-800" size={20} /> : null}
                </span>
            </p>
            <p>{update.description}</p>
            <p className="text-xs text-gray-500 text-right mb-0">
                {new Date(update.updated_at).toLocaleString('en-US',
                    { month: 'long', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}
            </p>
        </div>
    );
};

export default TicketUpdate;