import React from "react";

interface LabelProps {
    category?: string
    classifier?: string
    text?:string
}

const PromptEngineeringCatLabel: React.FC<LabelProps> = ({ category }) => {
    return(<>
        { (category?.toUpperCase() === "IPE" || category?.toUpperCase() === "IVSPE") && <span className="text-xs font-semibold text-center py-1 px-2 block w-10 h-6 uppercase rounded text-white bg-sky-500 uppercase mr-2">IPE</span> }
        { (category?.toUpperCase() === "IVPE" || category?.toUpperCase() === "IVSPE") && <span className="text-xs font-semibold text-center py-1 px-2 block w-10 h-6 uppercase rounded text-white bg-sky-700 uppercase mr-2">IVPE</span> }
        { (category?.toUpperCase() === "SPE" || category?.toUpperCase() === "IVSPE") && <span className="text-xs font-semibold text-center py-1 px-2 block w-10 h-6 uppercase rounded text-white bg-sky-900 uppercase mr-2">SPE</span> }
    </>);
}

export default PromptEngineeringCatLabel;