import React, {useEffect} from "react";
import ReactGA from "react-ga4";

interface CookieBannerProps {
    mustAccept: boolean;
}

const CookieBanner: React.FC<CookieBannerProps> = ({mustAccept}) => {
    // For testing:
    //localStorage.removeItem('cookies');

    const [showModal, setShowModal] = React.useState(localStorage.getItem( 'cookies' ) || "1");

    if (!mustAccept) {
        ReactGA.initialize('G-CX9LL68KRJ');
    }

    const closeModal = () => {
        setShowModal("0");
        localStorage.setItem( 'cookies', "0");
    }

    const acceptedModal = () => {
        document.body.classList.remove('overflow-hidden');
        setShowModal("0");
        localStorage.setItem( 'cookies', "0");
        ReactGA.initialize('G-CX9LL68KRJ');
    }

    useEffect(() => {
        if (mustAccept && showModal === "1") {
            document.body.classList.add('overflow-hidden');
        }
    });

    const HeaderAndText = () => {
        return(<div className="flex flex-col items-start mb-3 me-4 md:items-center md:flex-row md:mb-0">
            <div className="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 dark:border-gray-600">
                <img src={require("../assets/img/logo/logo.png")} className="h-6 me-2"
                     alt="Crafting AI Prompts"/>
                <span className="self-center text-lg font-semibold whitespace-nowrap dark:text-white mr-8">Cookies</span>
            </div>
            <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400 pt-4">
                This website uses cookies to ensure its proper functioning and to enhance your user experience. Essential cookies are necessary for the website to work correctly, such as those for preferences like dark mode and site navigation. We also use Google Analytics cookies to analyze traffic and understand how users interact with our website.
                <br /><br />If you prefer not to have your information stored, you can adjust your browser settings to block cookies or leave the website. By continuing to use our site, you consent to our use of cookies.</p>
        </div>);
    }

    const MustAcceptContent = () => {
        return(
            <>
                <div className={"flex flex-col md:flex-row justify-center"}>
                    <HeaderAndText />
                </div>
                <div className={"flex justify-center mt-5"}>
                    <button
                        onClick={acceptedModal} data-dismiss-target="#cookie-banner" type="button"
                        className={"px-5 p-2 bg-slate-800 hover:bg-green-900 border border-white/50 text-white rounded-lg md:mx-[20%] md:w-full"}
                    >
                        Accept
                    </button>
                </div>
            </>);
    }

    const OptionalCookieBannerContent = () => {
        return(
            <>
                <HeaderAndText />
                <div className="flex items-center flex-shrink-0">
                    <button onClick={closeModal} data-dismiss-target="#cookie-banner" type="button"
                            className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close banner</span>
                    </button>
                </div>
            </>
        );
    }

    const CookieBannerFactory = () => {
        const cookieBannerClass = mustAccept ? "transform -translate-y-1/2 top-1/2 left-1/2 w-[calc(80%-2rem)]" : "left-1/2 bottom-6 flex flex-col md:flex-row w-[calc(100%-2rem)]";

        return(
            <div id="cookie-banner"
                    className={"fixed z-50 justify-between p-4 -translate-x-1/2 bg-white border border-gray-100 rounded-lg shadow-sm lg:max-w-7xl dark:bg-gray-700 dark:border-gray-600 " + cookieBannerClass}>
                { mustAccept ? <MustAcceptContent /> : <OptionalCookieBannerContent /> }
            </div>);
    }
    
    // Show component based on if we should show it, if we must accept it, or hide it.
    if (showModal === "1") {
        if (mustAccept) {
            return(
                <div className="bg-slate-800 bg-opacity-95 w-full h-full fixed z-50 top-0 overflow-hidden">
                    <CookieBannerFactory />
                </div>);
        }
        return (
            <CookieBannerFactory />
        );
    }else {
        return(<></>);
    }
}

export default CookieBanner;