import { useAuth } from '../../hooks/auth'
import React, {useEffect} from 'react'
import GuestLayout from "../../components/dashboard/Layouts/GuestLayout";

const Logout = () => {
    const { logout } = useAuth();

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Logout"
        logout()
            .then(() => window.location.href = '/')
            .catch(()  => {
               // Do nothing
            });
    }, [logout]);

    return (
        <GuestLayout>
            <p>Logging out...</p>
        </GuestLayout>
    )
}

export default Logout
