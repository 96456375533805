import React, {useEffect} from "react";
// Layouts
import DefaultLayout from "../layouts/default";
// Components
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import BaseSection from "../components/base/Section";
import BaseButton from "../components/base/Button";

function Feedback() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Feedback"
  }, []);

  return (
    <div className="Feedback dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Feedback"
                      buttons={[
                        {title: "Feedback", url: "/feedback#feedback"}
                      ]}
        />

        {/* <!-- Blogs section --> */}
        {/*<section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white">*/}
        {/* <!-- FAQ section --> */}
        <section className="w-full my-14">
          <BaseSection>
            <div
                data-aos="fade-right"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-6 hidden lg:block"
            >
              <div className="w-full">
                <h3 id="feedback">
                  Your <span className="text-header-gradient">feedback</span> is valuable!
                </h3>
                <p>
                  Thank you for taking the time to participate in our survey! We're working on integrating this into our website soon, but for now, this form will help us gather your valuable feedback. 😊
                </p>

                <p>
                  The form has two options, one: providing feedback on the website, and two: leaving a review for us to publish on the website later on.
                  You're free to fill in one of them, or both. We welcome all entries!
                </p>

                <p>
                  Please note: do NEVER share any personal information in this form. We will never ask for it, and we will never store it. If you have any questions, please contact us directly.
                  We only ask you for your e-mail address so we can contact you if we have any questions about your feedback.
                </p>
                <p>If the form on the right side does not load, you can <a href={"https://docs.google.com/forms/d/e/1FAIpQLScYuY4jizwYDVhZtRUFT6R567io2Ksar71LxRhKp61L6l371w/viewform?usp=sf_link"} className={"text-sky-500"} rel="noreferrer" target="_blank">click here.</a></p>

                <hr className={"border-slate-600"} />

                <h3 id="share-online" className={"mt-12"}>
                  <span className="text-header-gradient">Share</span> the mission
                </h3>
                <p>
                  Next to providing feedback, you can also help us by sharing our mission with your friends, family, and colleagues. The more people know about us, the more we can help sharing free valuable insights about Prompt Engineering!
                </p>
                <BaseButton url={"https://www.linkedin.com/sharing/share-offsite/?url=craftingaiprompts.org"}
                            styles="px-10 py-4 bg-inherit text-gradient border border-[#4A8FF6] text-base"
                  targetBlank={true}
                >
                  Share this link on LinkedIn
                </BaseButton>
              </div>
            </div>
            <div
                data-aos="fade-left"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-6 px-4 sm:px-6 mt-8"
            >
              <iframe
                  title={"Feedback form"}
                  src="https://docs.google.com/forms/d/e/1FAIpQLScYuY4jizwYDVhZtRUFT6R567io2Ksar71LxRhKp61L6l371w/viewform?embedded=true"
                  width="540" height="1220">Laden…
              </iframe>
            </div>


          </BaseSection>
        </section>

        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default Feedback;
