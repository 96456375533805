// Components
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import DocumentationNav from "./DocumentationNav";

function FrameworkCheatSheet() {
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Cheatsheet"
    }, []);

    return (
        <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="cheatsheet">
            <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
                <DocumentationNav page="Framework" />

                <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="prompt-injection"
                     data-aos="fade-up"
                     data-aos-delay="100">
                    <h1 className="text-bold">Crafting AI Prompts Framework <span className="text-header-gradient">Cheatsheet</span></h1>
                    <p>Start by using this cheatsheet on your desk, and learn the framework.
                        In no-time you'll find out that you understand each component and don't need this cheatsheet anymore! <br />
                    Download link below.</p>
                    <div className={"mx-4"}>
                        <img
                        src={require("../../assets/img/framework/craftingai-cheatsheet.jpg")} alt="Framework cheatsheet" className={"mb-8"} />
                        <Link to={require("../../assets/img/framework/craftingai-cheatsheet.jpg")} target={"_blank"} download
                              className={"px-10 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white block"}>
                            <span className={"flex center justify-center"}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"/>
                                </svg>
                                <span>Download for free</span>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FrameworkCheatSheet;
