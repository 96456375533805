import React from "react";

interface LabelProps {
    category?: string
    classifier?: string
    text?:string
}

const AdversarialPromptingLabel: React.FC<LabelProps> = ({ category }) => {
    return(<>
        { (category?.toUpperCase() === "PI") && <span className="text-xs font-semibold text-center py-1 px-2 block w-20 h-6 uppercase rounded text-white bg-red-500 uppercase mr-2">INJ</span> }
        { (category?.toUpperCase() === "PL") && <span className="text-xs font-semibold text-center py-1 px-2 block w-20 h-6 uppercase rounded text-white bg-red-700 uppercase mr-2">LEAK</span> }
        { (category?.toUpperCase() === "PJ") && <span className="text-xs font-semibold text-center py-1 px-2 block w-20 h-6 uppercase rounded text-white bg-red-900 uppercase mr-2">JAIL</span> }
    </>);
}

export default AdversarialPromptingLabel;