import RiskLabel from "../../../components/labels/RiskLabel";
import BaseButton from "../../../components/base/Button";
import {Link} from "react-router-dom";
import React from "react";
import AdversarialPromptingLabel from "../../../components/labels/AdversarialPromptingLabel";

function WebsitePromptInjection() {
    return(
        <>
            <div className={"flex col-3 mb-1"}  id="website-injection">
                <h2 className={"mb-1"}><span className="text-header-gradient">Website</span> redirect</h2>
                <div className={"mt-3 ml-4"}>
                    <RiskLabel category={"risk"} classifier="LOW" /> <RiskLabel category={"impact"} classifier="MEDIUM" />
                </div>
                <div className={"mt-3 ml-4"}>
                    <AdversarialPromptingLabel category={"PI"} />
                </div>
            </div>
            <p>Since September 27, 2023, ChatGPT has been equipped with the ability to browse the internet, significantly enhancing its capability to access and provide current and precise information. Prior to this update, its knowledge was confined to the data it was trained on, limiting its scope.</p>
            <p>When you request ChatGPT to retrieve specific information from the internet, it embarks on a virtual journey, browsing through webpages based on search results to gather the necessary data. This process is efficiently executed without requiring any intervention from the user.</p>
            <p>However, this feature introduces a certain level of risk. Although users can monitor the webpages ChatGPT visits, there's no direct insight into the content of these websites. This limitation led me to conduct an intriguing experiment.</p>

            <h3 id={"website-injection-robottxt"}>Robots.txt</h3>
            <p>In the realm of website management, the robots.txt file plays a crucial role in dictating the access that search engines and other automated services, like Google or ChatGPT, have to your website's content. By utilizing this file, or through specific user agent settings within your application, you can effectively control whether these entities are allowed to train on or use your website's data.</p>
            <p>For ChatGPT in particular, it recognizes a unique identifier. If you wish to set specific access rules for ChatGPT, you can target its specific User-Agent, which is "ChatGPT-User". This allows for more tailored control over what content ChatGPT can access from your site, ensuring that you retain authority over your online data and its use.</p>

            <p className="block p-4 mx-3 bg-sky-300 rounded-t dark:bg-sky-800" style={{"marginBottom":"-1.3em"}}>Robots.txt file</p>
            <p className="block p-4 mx-3 bg-sky-100 rounded-b dark:bg-sky-950">
                User-agent: ChatGPT-User<br />
                Disallow:<br />
                Allow: /directory-1/ <br />
                Allow: /directory-2/</p>
            <BaseButton url={"https://platform.openai.com/docs/plugins/bot"} targetBlank={true} styles="max-w-full px-8 py-4 bg-color[#3D5A6C] border border-[#0c66ee] dark:text-white">
                Read more about ChatGPT-User Agent
            </BaseButton>
            <p className="block p-4 mx-3 bg-sky-300 rounded-t dark:bg-sky-800" style={{"marginBottom":"-1.3em"}}>OpenAI mentions on their website:</p>
            <p className="block p-4 mx-3 bg-sky-100 rounded-b dark:bg-sky-950">"Web pages crawled with the ChatGPT-User/1.0 user agent is primarily used to answer a live query from a user that submits a question or prompt to ChatGPT through its browsing feature. When content is retrieved from web pages by our user agent, it is used by our models to provide an answer to the user, our models will include a link to the websource. ChatGPTis trained to not repeat information verbatim from the data (although this is not perfect), but it will paraphrase, translate, summarize, and abstract information as requested by the user."</p>

            <h3 id={"website-injection-the-injection"}>The Website Prompt Injection</h3>
            <p>You can also employ this method to direct a bot to a specific page on your site, such as <Link to={"/ChatGPT.html"} className={"text-sky-400"} target={"_blank"}>/ChatGPT.html</Link>, which I experimented with. This approach won't pose a significant risk if the prompt remains visible on the page, but it's an interesting demonstration of potential outcomes.</p>
            <p>By placing the right "prompt" on your webpage, you can influence ChatGPT's responses, essentially guiding it to perform tasks as per your directive, rather than strictly following user queries.</p>

            <h3 id={"website-injection-risk-assessment"}>Risk Assessment</h3>
            <p>
                The tactic of Website Prompt Injection is classified as <RiskLabel category={"risk"} classifier="LOW" />  but with a <RiskLabel category={"impact"} classifier="MEDIUM" />. <br />
                This occurs when users are unaware of the underlying processes in ChatGPT, leading to lack of control over the information it generates. This can lead to the spread of misinformation,
                posing significant risks, particularly when ChatGPT is integrated with automation tools like Zapier (<RiskLabel category={"impact"} classifier="MEDIUM" />).</p>
            <p className={"pb-5"}>The likelihood of impact depends on the target website's prominence.
                If a low-value website is created solely for this purpose, its impact is minimal as it's unlikely to feature in search results (<RiskLabel category={"risk"} classifier="LOW" />).
                However, if a high-value website is compromised and targeted, the impact can be significant (<RiskLabel category={"risk"} classifier="MEDIUM" />).
                Ultimately, this issue is more related to the security of the website being attacked, which is why the risk of such an injection is considered <RiskLabel category={"risk"} classifier="LOW" />.</p>

            <hr className={"pb-5"} />
        </>);
}

export default WebsitePromptInjection;