import React, {useEffect, useRef} from "react";

// Layouts
import DefaultLayout from "../../layouts/default";
// Components
import ThreePhasesSection from "../../components/sections/ThreePhases";
import BackToTop from "../../components/base/BackToTop";
import HeaderBanner from "../../components/sections/HeaderBanner";
import {useLocation} from "react-router-dom";
import Button from "../../components/base/Button";
import TestList from "../../components/quiz/TestList";
import {Check} from "lucide-react";

function CaipTest() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Get certified Prompt Engineering | CAIP 1 IPE"
  }, []);

  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
    goToLocation(-130);
  });

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    goToLocation(-20);
  });

  function goToLocation(yOffset: number) {

    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        const element = document.getElementById(lastHash.current);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }

        lastHash.current = '';
      }, 50);
    }
  }

  const headerButtons = () => {
    if (localStorage.getItem("username") !== null && localStorage.getItem("username") !== undefined) {
      return [
        {title: "Dashboard", url: "/dashboard/overview"},
        {title: "Take the test", url: "/dashboard/quiz"}
      ]
    }


    return [
      {title: "No account yet? Register", url: "/register"},
      {title: "Login", url: "/login"},
    ]
  }

  return (
    <div className=" dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Test - Information"
                      heightStyle={"h-[440px]"}
                      buttons={headerButtons()}
        />

        <section className="relative flex max-w-full sm:mx-6 shadow sm:rounded-2xl overflow-hidden dark:bg-slate-900 dark:text-white">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 dark:text-white"
          data-aos="fade-up">
            <div className={"max-w-6xl mx-8"} id="test-information">
              <h3>
                CAIP 1 - IPE - <span className="text-header-gradient">certification test</span>
              </h3>

              <div className="flex flex-col gap-8 mb-8">
                {/* Test Information Table */}
                <div className="w-full">
                  <div className="grid md:grid-rows-[auto_1fr] md:grid-cols-3 rounded-lg overflow-hidden border border-gray-200 dark:border-gray-700">
                    {/* Headers Row */}
                    <div className="bg-blue-500 text-white px-4 py-2 md:col-start-1 font-bold flex items-center justify-center text-center">Test</div>
                    <div className="bg-gray-100 dark:bg-gray-800 px-4 py-2 text-center md:row-start-2">CAIP 1 (IPE)</div>
                    
                    <div className="bg-blue-500 text-white px-4 py-2 md:col-start-2 md:row-start-1 font-bold flex items-center justify-center text-center">Description</div>
                    <div className="bg-gray-100 dark:bg-gray-800 px-4 py-2 md:col-start-2 md:row-start-2">
                      <div className="md:min-h-[40px] flex items-center">
                        Entry level test to demonstrate your ability to craft effective AI prompts (IPE) via the Crafting AI Prompts Framework.
                      </div>
                    </div>
                    
                    <div className="bg-blue-500 text-white px-4 py-2 md:col-start-3 md:row-start-1 font-bold flex items-center justify-center text-center">Available</div>
                    <div className="bg-gray-100 dark:bg-gray-800 px-4 py-2 md:col-start-3 md:row-start-2">
                      <div className="flex items-center justify-center">
                        <Check className="text-green-500 mr-2" />
                        <span>(on request)</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Test Requirements Table */}
                <div className="w-full">
                  <div className="grid md:grid-rows-2 md:grid-cols-4 rounded-lg overflow-hidden border border-gray-200 dark:border-gray-700">
                    {/* Headers Row */}
                    <div className="bg-blue-500 text-white px-4 py-2 md:col-start-1 md:row-start-1 font-bold flex items-center justify-center text-center">Questions</div>
                    <div className="bg-gray-100 dark:bg-gray-800 px-4 py-2 text-center md:col-start-1 md:row-start-2">20</div>
                    
                    <div className="bg-blue-500 text-white px-4 py-2 md:col-start-2 md:row-start-1 font-bold flex items-center justify-center text-center">Minutes to complete</div>
                    <div className="bg-gray-100 dark:bg-gray-800 px-4 py-2 text-center md:col-start-2 md:row-start-2">10</div>
                    
                    <div className="bg-blue-500 text-white px-4 py-2 md:col-start-3 md:row-start-1 font-bold flex items-center justify-center text-center">Score to pass</div>
                    <div className="bg-gray-100 dark:bg-gray-800 px-4 py-2 text-center md:col-start-3 md:row-start-2">85% or higher</div>
                    
                    <div className="bg-blue-500 text-white px-4 py-2 md:col-start-4 md:row-start-1 font-bold flex items-center justify-center text-center">Retry interval*</div>
                    <div className="bg-gray-100 dark:bg-gray-800 px-4 py-2 text-center md:col-start-4 md:row-start-2">14 days</div>
                  </div>
                </div>
              </div>

              <p>* If you fail the test, you have to wait this number of days before you can try again.</p>

              <h2>Preparation</h2>
              <p className="mb-8">
                To prepare for the CAIP 1 - IPE certification test, you can use the following resources:
              </p>
              <ul className="list-disc list-inside space-y-2 mb-8 ml-6">
                <li><a href="/documentation/large-language-models#llms" className="text-blue-500 hover:underline">Introduction to Prompt Engineering</a>
                  <ul className="list-disc list-inside space-y-2 pl-7 mt-2">
                    <li><a href="/documentation/large-language-models#what-is-an-llm" className="text-blue-500 hover:underline">What is an LLM</a></li>
                    <li><a href="/documentation/large-language-models#prediction-based" className="text-blue-500 hover:underline">Prediction based</a></li>
                    <li><a href="/documentation/large-language-models#token-based" className="text-blue-500 hover:underline">Token based</a></li>
                  </ul>
                </li>
                <li><a href="/documentation/prompt-engineering#prompt-engineering" className="text-blue-500 hover:underline">Prompt Engineering*</a></li>
                <li><a href="/documentation/framework#documentation" className="text-blue-500 hover:underline">Crafting AI Prompts - Framework (IPE)*</a></li>
                <li><a href="/documentation/prompt-techniques#prompt-techniques" className="text-blue-500 hover:underline">Prompt Engineering Techniques*</a></li>
                <li><a href="/documentation/adversarial-prompting#adversarial-prompting" className="text-blue-500 hover:underline">Adversarial Prompting</a>
                  <ul className="list-disc list-inside space-y-2 pl-7 mt-2">
                    <li><a href="/documentation/adversarial-prompting/prompt-injections#prompt-injections" className="text-blue-500 hover:underline">Prompt Injections</a></li>
                    <li><a href="/documentation/adversarial-prompting/prompt-leaking#prompt-leaking" className="text-blue-500 hover:underline">Prompt Leaking</a></li>
                    <li><a href="/documentation/adversarial-prompting/prompt-jailbreak#prompt-jailbreak" className="text-blue-500 hover:underline">Prompt Jailbreak</a></li>
                  </ul>
                </li>
              </ul>

              <p className={"mb-8"}>* You need to review the entire page.</p>
              <p className={"mb-8"}><strong>Please note</strong> that for this test, you should know what "IVPE" stands for, as it is mentioned in the "Prompt Engineering" section. However, you don’t need to understand the IVPE framework documentation; this will be covered in the CAIP 1 - IVPE certification.</p>

              <h2>How does the test environment look like?</h2>
              <p>When you start the quiz, you'll enter this environment:</p>
              <img src={require("../../assets/img/quiz/test_example_screenshot.png")} alt="Test example screenshot" className={"w-full mb-8"} />

              <p>Please note: this screenshot only includes 17 questions, while the actual test has 20.</p>
              <p>What you can see on this image is the following:</p>
              <ul className="list-disc list-inside space-y-2 mb-8 ml-6">
                <li>On the left top you can see the time you've left to complete te test. If this time runs out, your test will be closed and submitted automatically.</li>
                <li>On the left side below the time, you'll see all questions (questions menu). You can navigate through them by clicking on them, but also you can see which questions you've bookmarked. It's only possible to go back, not forward through the questions.
                  <ul className="list-disc list-inside space-y-2 pl-7 mt-2 pb-2">
                    <li>Dark blue questions: You've answered this question</li>
                    <li>Light blue questions: You're currently at this question (or have it open)</li>
                    <li>Gray questions: You have not yet seen this question and can get there by clicking on the next button.</li>
                  </ul>
                </li>
                <li>On the right top side of the screen, you will see at which question you're currently.</li>
                <li>Then there is a green bar, that slowly turns gray. This indicates how much time you've left. When time is running out, it will turn red.</li>
                <li>Then there is a box with the actual question that you've to answer. You can click on A, B, C or D to answer it.</li>
                <li>Then there are three buttons:
                  <ul className="list-disc list-inside space-y-2 pl-7 mt-2 pb-2">
                    <li>Previous: Go back to the previous question.</li>
                    <li>Bookmark: Bookmark the question to review it later*. If you bookmarked a question, this button will change to "Remove Bookmark" to remove the bookmark again.</li>
                    <li>Next: Go to the next question (only available if you did select an answer). On the final question this button changes to "Submit".</li>
                  </ul>
                </li>
              </ul>

              <p>* When you bookmark a question, this will be marked in the Questions menu. If you bookmark a question, it's not possible to submit the test. The application will first notify you that you've still bookmarked questions, to review or to accept and actually submit the quiz.</p>

              <h3>Please be aware:</h3>
              <p>It's important to only start the test when you're fully prepared. The test cannot be paused, and if you close it, you'll automatically fail once the time runs out. There is no option to reopen the quiz.</p>
              <p>Additionally, each time you take the test, the questions will be different, as they are randomly selected from a database.</p>

              <h3>How do I get access?</h3>
              <p>At this moment, you can create an account by clicking on the "Login" button in the top-right corner of this website. Please note: ensure you use your actual name and email address. Although your email will not be verified, the name you provide will appear on your completion certificate. If it's incorrect, your certificate will reflect that.</p>
              <p>Once you log in with your newly created account, you can request access to the test by clicking the corresponding button. An admin will review your account and grant access, which may take up to 3 days (in most cases). Please note that you will not receive an email or notification, so be sure to check your dashboard after three days to start the test.</p>

              <p className={"mb-8"}>If you understand the information above and have reviewed the recommended resources, you're ready to begin!</p>

              <Button url={"/register"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white mr-5">Register</Button>
              <Button url={"/login"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white mr-5 mb-5">Login</Button>
              <Button url={"/test/terms"} styles="max-w-full px-8 py-4 border border-[#0c66ee] dark:text-white">Terms and Conditions</Button>
            </div>
          </div>
        </section>

        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default CaipTest;
