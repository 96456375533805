import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect} from "react";
import ProfileEdit from "../../components/dashboard/profile/profileEdit";

const Profile = () => {
    const { user } = useAuth({ middleware: 'auth' })

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Profile"
    }, []);


    return(
        user ?
            <AppLayout
                header={
                    <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                        User Profile
                    </h2>
                }>
                <div className="py-12 mx-3 lg:mx-0">

                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4">
                        <div className="grid grid-cols-1 gap-4">
                            <div className="profile-section overflow-hidden md:mr-4 mb-4 lg:mb-0">
                                 <ProfileEdit />
                            </div>
                        </div>
                    </div>

                </div>
            </AppLayout>
            :
            <AppLayout
                header={
                    <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                        Authenticating...
                    </h2>
                }>
            </AppLayout>
    )
}

export default Profile
