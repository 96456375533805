import AuthCard from '../../components/dashboard/AuthCard'
import AuthSessionStatus from '../../components/dashboard/AuthSessionStatus'
import AuthValidationErrors from '../../components/dashboard/AuthValidationErrors'
import Button from '../../components/dashboard/Button'
import GuestLayout from '../../components/dashboard/Layouts/GuestLayout'
import Input from '../../components/dashboard/Input'
import Label from '../../components/dashboard/Label'
import { useAuth } from '../../hooks/auth'
import React, {useEffect, useState} from 'react'
import { Link, NavLink} from 'react-router-dom';
import {ReactComponent as Logo} from "../../assets/img/logo/crafting-ai-logo.svg";

const Login = () => {

  const { login } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/dashboard'
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)
  const [loggingIn, setLoggingIn] = useState(false);

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Login"
  }, []);

  const submitForm = async event => {
    event.preventDefault()
    setLoggingIn(true);
    await login({email, password, setErrors, setStatus}).then((res) => {
      setLoggingIn(false);
    }).catch(() => {
      setLoggingIn(false);
    })
  }

  return (
    <GuestLayout>
      <AuthCard
        logo={
          <Link to="/">
            <Logo />
          </Link>
        }>
        {/* Session Status */}
        <AuthSessionStatus className="mb-4" status={status} />
        {/* Validation Errors */}
        <AuthValidationErrors className="mb-4" errors={errors} />
        <form onSubmit={submitForm}>
          {/* Email Address */}
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={email}
              className="block mt-1 w-full p-2"
              onChange={event => setEmail(event.target.value)}
              required
              autoFocus
            />
          </div>
          {/* Password */}
          <div className="mt-4">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              className="block mt-1 w-full p-2"
              onChange={event => setPassword(event.target.value)}
              required
              autoComplete="current-password"
            />
          </div>
          {/* Remember Me */}
          <div className="block mt-4">
            <label
              htmlFor="remember_me"
              className="inline-flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                name="remember"
                className="rounded border-gray-300 text-indigo-600
                shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <span className="ml-2 text-sm text-gray-600">
                  Remember me
              </span>
            </label>
          </div>
          <div className="flex items-center justify-between mt-4">
            <NavLink
                to="/register"
                className="underline text-sm text-gray-600 hover:text-gray-900"
            >
              No account yet? Register
            </NavLink>
            <div className="flex items-center">
              {/*<NavLink*/}
              {/*    to="/forgot-password"*/}
              {/*    className="underline text-sm text-gray-600 hover:text-gray-900 mr-3"*/}
              {/*>*/}
              {/*  Forgot your password?*/}
              {/*</NavLink>*/}
              <Button className={`ml-3 ${loggingIn ? 'bg-gray-500' : ''}`} type={"submit"} disabled={loggingIn}>
                {loggingIn ? 'Loading...' : 'Login'}
              </Button>
            </div>
          </div>
        </form>
      </AuthCard>
    </GuestLayout>
  )
}

export default Login
