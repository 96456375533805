import React from "react";
import RiskLabel from "../../../components/labels/RiskLabel";
import {Link} from "react-router-dom";
import AdversarialPromptingLabel from "../../../components/labels/AdversarialPromptingLabel";

function CustomGPTLeaking() {
    return(
        <>
            <div className={"flex col-2 mb-1"} id="custom-gpt-leaking">
                <h2 className={"mb-1"}><span className="text-header-gradient">Custom GPT</span></h2>
                <div className={"mt-3 ml-4"}>
                    <RiskLabel category={"risk"} classifier="HIGH" /> <RiskLabel category={"impact"} classifier="HIGH" />
                </div>
                <div className={"mt-3 ml-4"}>
                    <AdversarialPromptingLabel category={"PL"} />
                </div>
            </div>
            <p>In today's rapidly evolving digital landscape, the ability to create and distribute your own GPT has become astonishingly accessible. Thanks to OpenAI's groundbreaking Custom GPTs and the advent of a dedicated  <Link to={"https://openai.com/blog/introducing-gpts"} className={"text-sky-400"} target={"_blank"}>GPT store</Link>, the realm of artificial intelligence is more democratized than ever.</p>
            <p>However, with great power comes great responsibility – and risk. The ease of crafting prompts for these GPTs is a double-edged sword. While you can tailor the AI to your specific needs, there's a caveat: other users can potentially access and use the input/files/instructions provided to the Custom GPT.</p>

            <h3 id={"custom-gpt-injection-pros"}>Pro's</h3>
            <p>One of the remarkable features of GPTs is their ability to consume vast amounts of information. By uploading files filled with data, users can enrich the AI, feeding it a wealth of knowledge and instructions. This process transforms the AI into a specialized tool, capable of providing insights and responses tailored to your unique subject matter.</p>
            <p>This capability opens up a world of possibilities. Imagine being able to prompt your AI with questions about content that isn't inherently available in the base model. This feature allows for a more customized and targeted interaction, tapping into uncharted areas of knowledge.</p>

            <h3 id={"custom-gpt-injection-the-injection"}>The Custom GPT prompt leaking</h3>
            <p>Yet, there's a significant concern that cannot be overlooked: the risk of user data access. What happens if a user of your custom GPT gains access to sensitive data fed into the system? This question raises critical issues about data privacy and security, which are paramount in the age of information.</p>

            <p>In fact, uncovering the underlying mechanics of a custom GPT model is as straightforward as posing a direct inquiry. Simply prompt ChatGPT to display the 'instructions' provided by the creator of the GPT you're interacting with. With a bit of fine-tuning and the right phrasing, this AI is capable of revealing its inner workings and the foundational guidelines it operates on. This level of transparency, while fascinating, also underscores the importance of understanding and controlling the access to such sensitive information within these advanced AI systems.</p>

            <h2 id={"custom-gpt-injection-risk-assessment"}>Risk assessment</h2>
            <p>This Prompt Injection was labeled as <RiskLabel category={"risk"} classifier="HIGH" /> <RiskLabel category={"impact"} classifier="HIGH" />. This classification underscores the potential dangers associated with the misuse of these advanced technologies.</p>
            <p>In scenarios where creators of a GPT model are cautious and only use data that is already publicly available on the internet, the risk remains somewhat contained (<RiskLabel category={"risk"} classifier="LOW" />). However, if this vigilance is not maintained, and sensitive or proprietary information is inadvertently uploaded to the GPT, the consequences could be severe (<RiskLabel category={"risk"} classifier="HIGH" />). This high impact stems from the possibility that once sensitive data is incorporated into a GPT model, it could become accessible to anyone using that GPT.</p>
            <p>The risk is amplified by the fact that OpenAI allows individuals to create custom GPT models. This democratization of technology, while beneficial in many respects, also opens the door for potential security oversights. Employees within a company might not fully comprehend the risks involved and could unintentionally upload confidential or intellectual property (IP) data to these models. Once uploaded, this information could be accessed by anyone using the GPT, leading to significant breaches of privacy and security (<RiskLabel category={"impact"} classifier={"HIGH"} />). </p>
            <p className={"pb-5"}>This scenario highlights the critical importance of strict data governance and awareness within organizations utilizing custom GPT models. Ensuring that all employees understand the implications of uploading data to these models is essential in mitigating the high risk and impact of potential data exposure.</p>

            <hr className={"pb-5"} />
        </>);
}

export default CustomGPTLeaking;