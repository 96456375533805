// Components
import React, {useEffect} from "react";
import WorkshopNav from "./WorkshopNav";
import BaseButton from "../../components/base/Button";

function WorkshopIntroduction() {
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Workshop Introduction"
    }, []);

    return (
        <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="introduction">
            <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
                <WorkshopNav page="Introduction" />

                <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="prompt-injection"
                     data-aos="fade-up"
                     data-aos-delay="100">
                    <h1 className="text-bold">Crafting AI Prompts Framework <span className="text-header-gradient">Workshop</span></h1>
                    <p>Welcome to the workshop! You've just received a handout from the workshop facilitator, which contains the instructions you need to follow. This webpage provides additional resources to help you validate your output or add an extra level of challenge to your work. Please note that these materials are optional, and the workshop can be completed without them.</p>
                    <p>If you choose to use the online materials, it's recommended to start with the transcript before beginning the workshop. The transcript is from an internal meeting of the marketing team. Due to unforeseen circumstances, the team is no longer able to plan the event, which is why your assistance is needed!</p>
                    <p>The meeting contains valuable information that could be beneficial for your preparation. You might find some useful insights there.</p>
                    <p>Head over to the Transcript page for further instructions.</p>
                    <div className={"pt-5"}>
                        <BaseButton url={"/workshop/transcript#transcript"} styles="max-w-full px-8 py-4 mr-5 mb-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                            Next: Transcript
                        </BaseButton>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WorkshopIntroduction;
