// Components
import React, {useEffect, useState} from "react";
import WorkshopNav from "./WorkshopNav";

function WorkshopEventTimeline() {
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Workshop Introduction"
    }, []);

    const timelineData = [
        { time: '12:00-12:20', events: [{ title: 'Welcome' }] },
        { time: '12:20-12:30', events: [{ title: 'Keynote speaker - Jeroen Egelmeers' }] },
        { time: '12:30-13:00', events: [{ title: 'Network' }] },
        { time: '13:00-13:45', events: [
                { title: 'Breakout session 1' },
                { title: 'Breakout session 2' },
                { title: 'Breakout session 3' },
                { title: 'Breakout session 4' }
            ]},
        { time: '13:45-14:00', events: [{ title: 'Network' }] },
        { time: '14:00-14:50', events: [{ title: 'Keynote speaker - Very special guest' }] },
        { time: '15:00-17:00', events: [{ title: 'Network & Drinks' }] },
    ]

    const getEventDetails = (event: string) => {
        switch (event) {
            case 'Welcome':
                return 'Opening remarks and introduction to the event.';
            case 'Keynote speaker - Jeroen Egelmeers':
                return 'An inspiring talk by Jeroen Egelmeers on industry trends.';
            case 'Network':
                return 'Opportunity to connect with other attendees and speakers.';
            case 'Breakout session 1':
            case 'Breakout session 2':
            case 'Breakout session 3':
            case 'Breakout session 4':
                return 'Focused discussions on specific topics. Choose the session that interests you most.';
            case 'Keynote speaker - Very special guest':
                return 'An exciting presentation by our very special guest speaker.';
            case 'Network & Drinks':
                return 'Conclude the event with refreshments and final networking opportunities.';
            default:
                return 'More information will be provided at the event.';
        }
    };
    const [hoveredEvent, setHoveredEvent] = useState<string>('');
    const [showContent, setShowContent] = useState<boolean>(false);

    const HiddenForSafety = () => {
            return (<>
                <div id="alert-additional-content-2"
                     className="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-black dark:text-red-400 dark:border-red-800"
                     role="alert">
                    <div className="flex items-center">
                        <svg className="flex-shrink-0 w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                             fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span className="sr-only">Info</span>
                        <span className="text-lg font-medium pl-2">Are you sure you want to see the solution?</span>
                    </div>
                    <div className="mt-2 mb-4 text-sm">
                        <p>It's recommended to try solving the problem yourself before viewing the solution.</p>
                    </div>
                    <div className="flex">
                        <button type="button" onClick={() => setShowContent(true)}
                                className="text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                            <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                 viewBox="0 0 20 14">
                                <path
                                    d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                            </svg>
                            Yes, show the content
                        </button>
                    </div>
                </div>
            </>);
    }

    const EventTimelineSolution = () => {
        return(
            <>
                <div className="p-8 bg-slate-100">
                    <h2 className="text-3xl font-bold mb-6 text-[#0070AD]">Event Timeline</h2>
                    <div className="space-y-4">
                        {timelineData.map((timeSlot, index) => (
                            <div key={index} className="flex items-stretch">
                                <div className="w-32 flex-shrink-0 font-semibold text-[#0070AD] pt-2">{timeSlot.time}</div>
                                <div className="flex-grow flex space-x-2">
                                    {timeSlot.events.map((event, eventIndex) => (
                                        <div
                                            key={eventIndex}
                                            className="flex-grow bg-white rounded-lg shadow transition-all duration-300 hover:shadow-lg border-l-4 border-sky-500"
                                            onMouseEnter={() => setHoveredEvent(`${index}-${eventIndex}`)}
                                            onMouseLeave={() => setHoveredEvent('null')}
                                        >
                                            <div className="p-4">
                                                <div className={`font-semibold ${hoveredEvent === `${index}-${eventIndex}` ? 'text-sky-900' : 'text-[#0070AD]'}`}>
                                                    {event.title}
                                                </div>
                                                {hoveredEvent === `${index}-${eventIndex}` && (
                                                    <div className="mt-2 text-sm text-gray-600">
                                                        {getEventDetails(event.title)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <p>For this: Claude was used: <a href={"https://claude.site/artifacts/b1c189fc-f0d9-48b6-b994-b15124bff246"} target={"_blank"} rel={"noreferrer"} className={"text-sky-500"}>see artifact</a>.</p>
            </>);
    }

    return (
        <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="timeline" style={{width: '100%'}}>
            <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6" style={{width: '100%'}}>
                <WorkshopNav page="Introduction" />

                <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section"
                     data-aos="fade-up"
                     data-aos-delay="100" style={{width: '100%'}}>
                    <h1 className="text-bold">Creating an <span className="text-header-gradient">event timeline</span></h1>
                    <p>Example of an interactive event timeline (generated with Generative AI):</p>

                    {
                        showContent ? <EventTimelineSolution /> : <HiddenForSafety />
                    }

                </div>
            </div>
        </section>
    );
}

export default WorkshopEventTimeline;
