import React from "react";
import LabelProps from "./LabelInterfaces";

const RiskLabel: React.FC<LabelProps> = ({ category, classifier }) => {
    return(<>
        {category?.toUpperCase() === "RISK" ?
            <span className="text-xs font-semibold py-1 px-2 uppercase rounded-l text-white bg-violet-600 uppercase">{category}:</span> :
            <span className="text-xs font-semibold py-1 px-2 uppercase rounded-l text-white bg-violet-900 uppercase">{category}:</span>
        }
        {classifier?.toUpperCase() === "HIGH" ? <span className="text-xs font-semibold py-1 px-2 uppercase rounded-r text-red-600 bg-red-200 uppercase">{classifier}</span>
            : classifier === "MEDIUM" ? <span className="text-xs font-semibold py-1 px-2 uppercase rounded-r text-orange-600 bg-orange-200 uppercase">{classifier}</span>
                : <span className="text-xs font-semibold py-1 px-2 uppercase rounded-r text-amber-600 bg-amber-200 uppercase">{classifier}</span>}
    </>);
}

export default RiskLabel;