import React from "react";
import RiskLabel from "../../../components/labels/RiskLabel";
import AdversarialPromptingLabel from "../../../components/labels/AdversarialPromptingLabel";


function InjectionOverview() {
    interface PromptInjectionOverviewRowProps {
        name: string;
        risk: string;
        impact: string;
        hashRef: string;
        category: string;
    }

    const PromptInjectionOverviewRow: React.FC<PromptInjectionOverviewRowProps> = ({ name, risk, impact, hashRef, category }) => {
        return(
            <a href={hashRef} className="grid grid-cols-3 md:grid-cols-5 border-b border-gray-600 block hover:bg-sky-900">
                <div className="col-span-1 hidden md:block py-2 px-2"><AdversarialPromptingLabel category={category} /></div>
                <div className="col-span-1 md:col-span-2 py-2 px-4 text-header-gradient">{name}</div>
                <div className="col-span-1 py-2 px-2"><RiskLabel category={"risk"} classifier={risk} /></div>
                <div className="col-span-1 py-2 px-2"><RiskLabel category={"impact"} classifier={impact} /></div>
            </a>
        );
    }

    //const baseDocUrl = "/documentation/adversarial-prompting";
    const injectionUrl = "/documentation/adversarial-prompting/prompt-injections";
    const leakingUrl = "/documentation/adversarial-prompting/prompt-leaking";
    const jailbreakUrl = "/documentation/adversarial-prompting/prompt-jailbreak";

    return(
        <>
            <div className={"flex col-2 mb-1"} id="prompt-injection-overview">
                <h2 className={"mb-1"}>Adversarial prompt <span className="text-header-gradient">overview</span></h2>
            </div>
            <p>On this page, you will find various Prompt Injections. Below, each prompt injection is listed along with its impact. The risk assessment for each is detailed in their respective overviews.</p>
            <div className="shadow-md rounded-lg overflow-hidden border-b">
                <div className="grid grid-cols-3 md:grid-cols-5 border-b">
                    <div className="col-span-1 hidden md:block py-2 px-2 pl-3 bg-slate-700 text-white font-bold">Category</div>
                    <div className="col-span-1 md:col-span-2 py-2 px-4 bg-slate-700 text-white font-bold">Adversarial prompt</div>
                    <div className="col-span-1 py-2 px-2 bg-slate-700 text-white font-bold">Risk</div>
                    <div className="col-span-1 py-2 px-2 bg-slate-700 text-white font-bold">Impact</div>
                </div>

                <PromptInjectionOverviewRow name={"Website redirect"} hashRef={injectionUrl + "#website-injection"} risk={"LOW"} impact={"MEDIUM"} category={"pi"} />
                <PromptInjectionOverviewRow name={"Image/Document"} hashRef={injectionUrl + "#image-injection"} risk={"HIGH"} impact={"HIGH"} category={"pi"}/>
                <PromptInjectionOverviewRow name={"CustomGPT"} hashRef={leakingUrl + "#custom-gpt-leaking"} risk={"HIGH"} impact={"HIGH"} category={"pl"} />
                <PromptInjectionOverviewRow name={"ASCII Art"} hashRef={jailbreakUrl + "#ASCII-Art-based-jailbreak"} risk={"HIGH"} impact={"HIGH"} category={"pj"} />
                <PromptInjectionOverviewRow name={"Many-shot"} hashRef={jailbreakUrl + "#many-shot-jailbreak"} risk={"HIGH"} impact={"HIGH"} category={"pj"} />
            </div>
            <p className={"pb-5"}>If you click on the row of a prompt injection, you will be automatically navigated to the corresponding section for more detailed information.</p>

            <hr className={"pb-5"} />
        </>);
}

export default InjectionOverview;