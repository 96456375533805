import React from "react";
import NavLink from "../NavLink";
import { ReactComponent as Logo } from "../../assets/img/logo/crafting-ai-logo.svg";

const Footer = () => {
    return (
    <footer className="max-w-screen-xl px-8 mx-auto">
      <div className="w-full">
        <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
          <div className="md:w-full lg:w-full w-full sm:w-1/2 xl:w-fit flex flex-col sm:flex-row sm:px-6 py-6 sm:py-12 sm:space-x-10 sm:border-r-0 border-[#DDDDDD]">
            <div className="sm:hidden xl:block mb-6 sm:mb-0">
              <a href="/">
                <Logo className="w-16 h-14 -mx-2"/>
              </a>
            </div>
            <ul className="space-y-4">
                <NavLink name="IPE Framework" url="/documentation/framework#documentation" />
                <NavLink name="IPVE Framework" url="/documentation/framework/visual#documentation" />
                <NavLink name="IPE Cheatsheet" url="/documentation/framework/cheatsheet" />
            </ul>
          </div>
          <div className="md:w-full lg:w-full w-full sm:w-1/2 xl:w-fit sm:px-16 py-6 sm:py-12">
            <ul className="space-y-4">
                <NavLink name="Prompt Engineering" url="/documentation/prompt-engineering#prompt-engineering" />
                <NavLink name="Prompt Techniques" url="/documentation/prompt-techniques#prompt-techniques" />
                <NavLink name="Adversarial Prompting" url="/documentation/adversarial-prompting#adversarial-prompting" />
            </ul>
          </div>
            <div className="md:w-full lg:w-full w-full sm:w-1/2 xl:w-fit sm:px-16 py-6 sm:py-12">
                <ul className="space-y-4">
                    <NavLink name="Prompt Architecture" url="/documentation/prompt-architecture#prompt-architecture" />
                    <NavLink name="Blogs" url="/blogs#blogs" />
                    <NavLink targetBlank="_blank" name="Contact" url="https://www.linkedin.com/in/jegelmeers/" />
                </ul>
            </div>
            <div className="md:w-full lg:w-full w-full sm:w-1/2 xl:w-fit sm:px-16 py-6 sm:py-12">
                <ul className="space-y-4">
                    <NavLink name="Test - Get Certified!" url="/test#test-information" />
                    <NavLink name="Test - Terms" url="/test/terms#test-terms" />
                    <NavLink name="Content Usage Policy" url="/content-policy#content-policy" />
                </ul>
            </div>
          </div>
      </div>
      <div className="py-8 sm:py-4 text-center text-sm text-[#666666] dark:text-white hover:text-gray-900">
        &copy; Copyright 2024 - Crafting AI Prompts Framework
      </div>
    </footer>
  );
};

export default Footer;
