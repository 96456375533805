export const formattingChatGPT =
    `<p>You can organize your text using headers, bold and italics for emphasis, and lists for clarity. Use inline code and code blocks to display programming code, and blockquotes to highlight important information. Create horizontal rules for thematic breaks, add links for easy navigation, and structure data with tables. These formatting options make your interactions with ChatGPT more structured and readable.</p>
    <div class="shadow-md rounded-lg overflow-hidden">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b">
            <div class="py-2 px-4 bg-sky-900 text-white font-bold">Formatting Type</div>
            <div class="py-2 px-4 bg-sky-900 text-white font-bold hidden md:block">Markdown Syntax</div>
            <div class="py-2 px-4 bg-sky-900 text-white font-bold hidden lg:block">Example Output</div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">H1 Header</div>
            <div class="py-2 px-4"><code># H1 Header</code></div>
            <div class="py-2 px-4 hidden lg:block"><h1 class="text-2xl font-bold">H1 Header</h1></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">H2 Header</div>
            <div class="py-2 px-4"><code>## H2 Header</code></div>
            <div class="py-2 px-4 hidden lg:block"><h2 class="text-xl font-bold">H2 Header</h2></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">H3 Header</div>
            <div class="py-2 px-4"><code>### H3 Header</code></div>
            <div class="py-2 px-4 hidden lg:block"><h3 class="text-lg font-bold">H3 Header</h3></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Bold</div>
            <div class="py-2 px-4"><code>**Bold**</code></div>
            <div class="py-2 px-4 hidden lg:block"><b>Bold</b></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Italics</div>
            <div class="py-2 px-4"><code>*Italics*</code></div>
            <div class="py-2 px-4 hidden lg:block"><i>Italics</i></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Bold and Italics</div>
            <div class="py-2 px-4"><code>***Bold and Italics***</code></div>
            <div class="py-2 px-4 hidden lg:block"><b><i>Bold and Italics</i></b></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Unordered List</div>
            <div class="py-2 px-4">
                <code>- Item 1</code><br>
                <code>- Item 2</code><br>
                <code>&nbsp;&nbsp;- Subitem 1</code><br>
                <code>&nbsp;&nbsp;- Subitem 2</code>
            </div>
            <div class="py-2 px-4 hidden lg:block">
                <ul class="list-disc list-inside">
                    <li>Item 1</li>
                    <li>Item 2
                        <ul class="list-disc list-inside ml-4">
                            <li>Subitem 1</li>
                            <li>Subitem 2</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Ordered List</div>
            <div class="py-2 px-4">
                <code>1. First item</code><br>
                <code>2. Second item</code><br>
                <code>&nbsp;&nbsp;1. Subitem 1</code><br>
                <code>&nbsp;&nbsp;2. Subitem 2</code>
            </div>
            <div class="py-2 px-4 hidden lg:block">
                <ol class="list-decimal list-inside">
                    <li>First item</li>
                    <li>Second item
                        <ol class="list-decimal list-inside ml-4">
                            <li>Subitem 1</li>
                            <li>Subitem 2</li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Inline Code</div>
            <div class="py-2 px-4"><code>\`code\`</code></div>
            <div class="py-2 px-4 hidden lg:block"><code>code</code></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Code Block</div>
            <div class="py-2 px-4">
                <code>\`\`\`python</code><br>
                <code>def hello_world():</code><br>
                <code>&nbsp;&nbsp;print("Hello, world!")</code><br>
                <code>\`\`\`</code>
            </div>
            <div class="py-2 px-4 hidden lg:block">
                <pre><code>def hello_world():
print("Hello, world!")</code></pre>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Blockquote</div>
            <div class="py-2 px-4"><code>&gt; This is a blockquote.</code></div>
            <div class="py-2 px-4 hidden lg:block"><blockquote class="border-l-4 border-gray-400 pl-4">This is a blockquote.</blockquote></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Horizontal Rule</div>
            <div class="py-2 px-4"><code>---</code></div>
            <div class="py-2 px-4 hidden lg:block"><hr></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b border-gray-600">
            <div class="py-2 px-4 text-header-gradient">Link</div>
            <div class="py-2 px-4"><code>[OpenAI](https://www.openai.com)</code></div>
            <div class="py-2 px-4 hidden lg:block"><a href="https://www.openai.com" class="text-blue-500">OpenAI</a></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-b-4 border-sky-900">
            <div class="py-2 px-4 text-header-gradient">Table</div>
            <div class="py-2 px-4">
                <code>| Header 1 | Header 2 |</code><br>
                <code>|----------|----------|</code><br>
                <code>| Row 1&nbsp;&nbsp;&nbsp;&nbsp;| Data 1&nbsp;&nbsp;&nbsp;|</code><br>
                <code>| Row 2&nbsp;&nbsp;&nbsp;&nbsp;| Data 2&nbsp;&nbsp;&nbsp;|</code>
            </div>
            <div class="py-2 px-4 hidden lg:block">
                <table class="min-w-full border border-gray-200">
                    <thead>
                        <tr>
                            <th class="py-1 px-2 border-b border-gray-200 text-white bg-sky-900">Header 1</th>
                            <th class="py-1 px-2 border-b border-gray-200 text-white bg-sky-900">Header 2</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="py-1 px-2 border-b border-gray-200">Row 1</td>
                            <td class="py-1 px-2 border-b border-gray-200">Data 1</td>
                        </tr>
                        <tr>
                            <td class="py-1 px-2 border-b border-gray-200">Row 2</td>
                            <td class="py-1 px-2 border-b border-gray-200">Data 2</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <br />
    <p>You can always ask the model to give a complete list by just asking what formatting ChatGPT supports.</p>`;