import React, {useState} from 'react';
import { Info, Eye, EyeOff, AlertCircle } from 'lucide-react';
import {useAuth} from "../../../hooks/auth";
import RoleEnum from "../../enums/RoleEnum";
import axios from "../../../libs/axios";

const ProfileEdit = () => {
    const { user } = useAuth({ middleware: 'auth' })
    const [isEditing, setIsEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteInput, setDeleteInput] = useState('');
    const [deleteError, setDeleteError] = useState('');

    const handleDeleteClick = () => {
        setShowDeletePopup(true);
    };

    const handleDeleteConfirm = async () => {
        if (deleteInput === 'DELETE') {
            try {
                await axios.delete('/user/delete-account', { data: { delete: true } }).then(
                    response => {
                        // Logout user
                        setShowDeletePopup(false);
                        setDeleteError('');
                        localStorage.removeItem('username');
                        window.location.reload();
                    }
                );
            } catch (error) {
                setDeleteError('An error occurred while deleting your account. Please try again later.');
            }
        } else {
            setDeleteError('Incorrect input. Please enter "DELETE" to confirm.');
        }
        setDeleteInput('');
    };

    // const handleEdit = () => {
    //     setIsEditing(true);
    //     setError('');
    // };
    //
    // const handleCancel = () => {
    //     setIsEditing(false);
    //     setCurrentPassword('');
    //     setError('');
    // };
    //
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (currentPassword) {
    //         console.log('Submitting updated user data:', { ...user, currentPassword });
    //         setIsEditing(false);
    //         setCurrentPassword('');
    //         setError('');
    //     } else {
    //         setError('Please enter your current password to update your profile.');
    //     }
    // };
    //
    // const handlePasswordChange = (e) => {
    //     e.preventDefault();
    //     if (newPassword === confirmPassword) {
    //         console.log('Changing password:', { currentPassword, newPassword });
    //         setIsChangingPassword(false);
    //         setCurrentPassword('');
    //         setNewPassword('');
    //         setConfirmPassword('');
    //         setError('');
    //     } else {
    //         setError('New passwords do not match. Please try again.');
    //     }
    // };
    //
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     //setUser(prevUser => ({ ...prevUser, [name]: value }));
    // };

    const InputField = ({ label, name, value, onChange, disabled = false, type = "text" }) => (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className={`w-full py-3 border rounded-md ${disabled ? 'bg-white border-0' : 'bg-white px-3'}`}
            />
        </div>
    );

    const PasswordField = ({ label, value, onChange }) => (
        <div className="mb-4 relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
            <input
                type={showPassword ? "text" : "password"}
                value={value}
                onChange={onChange}
                className="w-full px-3 py-2 border rounded-md pr-10"
            />
            <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            >
                {showPassword ? <EyeOff className="h-5 w-5 text-gray-500" /> : <Eye className="h-5 w-5 text-gray-500" />}
            </button>
        </div>
    );

    const Button = ({ onClick, type, children, variant = "primary" }) => (
        <button
            onClick={onClick}
            type={type}
            className={`px-4 py-2 rounded-md ${
                variant === "primary"
                    ? "bg-blue-600 text-white hover:bg-blue-700" :
                    variant === "disabled" ? "bg-gray-200 text-gray-800 hover:bg-gray-300" :
                        variant === "warning" && "bg-red-400 text-white hover:bg-red-700"
            }`}
        >
            {children}
        </button>
    );

    const RoleLabel = ({ role }) => {
        const bgColor = role === RoleEnum.Registered ? 'bg-green-100 text-green-800' :
            role === RoleEnum.DefaultUser ? 'bg-sky-100 text-sky-800' :
                role === RoleEnum.Trainer ? 'bg-orange-100 text-orange-800' :
                    role === RoleEnum.Moderator ? 'bg-purple-100 text-purple-800' :
                        'bg-red-100 text-red-800'; // admin
        return (
            <span className={`absolute top-2 right-2 px-6 py-2 m-4 rounded-full text-xs font-semibold ${bgColor}`}>
               {RoleEnum[user.role]}
            </span>
        );
    };

    const ErrorMessage = ({ message }) => (
        message ? (
            <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded relative" role="alert">
        <span className="flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
            {message}
        </span>
            </div>
        ) : null
    );

    return (
        <div className=" mx-auto p-6 bg-white rounded-lg shadow-lg relative">
            <RoleLabel role={user.role} />
            <h2 className="text-2xl font-bold mb-6">User Profile</h2>
            <ErrorMessage message={error} />
            {/*{!isChangingPassword ? (*/}
            {/*    <form onSubmit={handleSubmit}>*/}
                <form>
                    <InputField
                        label="Name"
                        name="name"
                        value={user.name}
                        // onChange={handleChange}
                        disabled={!isEditing}
                    />
                    <InputField
                        label="Email"
                        name="email"
                        value={user.email}
                        // onChange={handleChange}
                        disabled={true}
                    />
                    <p className="text-sm text-gray-500 mt-1 mb-4 flex items-center">
                        <Info className="w-4 h-4 mr-1" />
                        Username or email changes require a new ticket.
                    </p>
                    {/*{isEditing && (*/}
                    {/*    <PasswordField*/}
                    {/*        label="Current Password"*/}
                    {/*        value={currentPassword}*/}
                    {/*        onChange={(e) => setCurrentPassword(e.target.value)}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*<div className="mt-6 space-x-4">*/}
                    {/*    {!isEditing ? (*/}
                    {/*        <>*/}
                    {/*            <Button onClick={handleEdit} type="button">Edit Profile</Button>*/}
                    {/*            <Button onClick={() => {setIsChangingPassword(true); setError('');}} type="button" variant="secondary">Change Password</Button>*/}
                    {/*        </>*/}
                    {/*    ) : (*/}
                    {/*        <>*/}
                    {/*            <Button type="submit">Save Changes</Button>*/}
                    {/*            <Button onClick={handleCancel} type="button" variant="secondary">Cancel</Button>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </form>
            {/*) : (*/}
            {/*    <form onSubmit={handlePasswordChange}>*/}
            {/*        <PasswordField*/}
            {/*            label="Current Password"*/}
            {/*            value={currentPassword}*/}
            {/*            // onChange={(e) => setCurrentPassword(e.target.value)}*/}
            {/*        />*/}
            {/*        <PasswordField*/}
            {/*            label="New Password"*/}
            {/*            value={newPassword}*/}
            {/*            // onChange={(e) => setNewPassword(e.target.value)}*/}
            {/*        />*/}
            {/*        <PasswordField*/}
            {/*            label="Confirm New Password"*/}
            {/*            value={confirmPassword}*/}
            {/*            // onChange={(e) => setConfirmPassword(e.target.value)}*/}
            {/*        />*/}
            {/*        /!*<div className="mt-6 space-x-4">*!/*/}
            {/*        /!*    <Button type="submit">Change Password</Button>*!/*/}
            {/*        /!*    <Button onClick={() => {setIsChangingPassword(false); setError('');}} type="button" variant="secondary">Cancel</Button>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*    </form>*/}
            {/*)}*/}
            <div className="mt-6 rounded-full bg-blue-100 border-l-4 border-sky-500 text-sky-700 p-4" role="alert">
                <span className={"text-sm flex"}>
                    <Info className="w-4 h-4 mr-3 pt-1" />
                    <i>
                        Profile created on: {
                            new Date(user.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
                        }
                    </i>
                </span>
            </div>

            <div className="mt-6 flex justify-end">
                <Button onClick={handleDeleteClick} variant={"warning"}>Delete Account</Button>
            </div>

            {showDeletePopup && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded shadow-lg w-1/2">
                        <h4>Delete account</h4>
                        <p>Are you sure you want to delete your account? Please enter "DELETE" to confirm.</p>
                        <p>Please note: <span className={"text-red-700 font-bold"}>There is NO turning back!</span><br /> All your certificates, test results, tickets, and other achievements or activities will be permanently deleted.
                        <br />Your earned certificate(s) will become INVALID after this action.</p>
                        <p>If you have any concerns regarding your account or would like to discuss it first, please raise a ticket in your dashboard. We will reach out as soon as possible.</p>
                        <input
                            type="text"
                            value={deleteInput}
                            onChange={(e) => setDeleteInput(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm border border-gray-300 p-2"
                        />
                        {deleteError && <p className="text-red-500 mt-2">{deleteError}</p>}
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => { setShowDeletePopup(false); setDeleteError(''); setDeleteInput(''); }}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDeleteConfirm}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfileEdit;