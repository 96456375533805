import React from "react";

interface YoutubeEmbedProps {
    embedId: string
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ embedId }) => (
    <div style={{"position": "relative", "paddingBottom": "56.25%", "height": "0", "overflow":"hidden"}}>
        <iframe
            src={`https://www.youtube.com/embed/${embedId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            style={{"position":"absolute", "top":"0", "left":"0", "width":"100%", "height":"100%"}}
        />
    </div>
);

export default YoutubeEmbed;