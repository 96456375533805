// Components
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import WorkshopNav from "./WorkshopNav";
import BaseButton from "../../components/base/Button";

function WorkshopTranscript() {
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Workshop Transcript"
    }, []);

    return (
        <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="transcript">
            <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
                <WorkshopNav page="Introduction" />

                <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="prompt-injection"
                     data-aos="fade-up"
                     data-aos-delay="100">
                    <h1 className="text-bold text-header-gradient">Transcript</h1>
                    <p>In preparation for the kick-off, our marketing team has meticulously reviewed all the feedback received from last year's event.
                        We kindly ask you or your team to consider these key takeaways as you plan and execute this year's kick-off event.
                    </p>
                    <p>First, extract the key takeaways from this transcript using Generative AI to craft your plan.
                       <br />Please don't wait for the feedback from the management team or progress from the marketing team.</p>
                    <p>You're now in charge of this event, and we fully trust in your work! <br />The download button is on the bottom of this page.</p>
                    <div className={"block p-4 m-4 bg-gray-100 rounded-md dark:bg-gray-950"}>
                        <h3>Feedback Discussion on Company Kick-off Event</h3>
                        <table className={"border-separate border-spacing-4 w-[50%] text-center border border-slate-100 mb-5"}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>May 1st, 2023</td>
                                    <td>14:00-15:15</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>14:00 - Dan: "Good afternoon, everyone. I'm glad to see you all here. Let's not forget to share a bit about our weekends before we dive into our main agenda. Who wants to start?"</p>
                        <p>14:02 - Sara: "I'll kick things off! I took a pottery class, which was amazing. Also, has anyone else noticed the new art installation downtown?"</p>
                        <p>14:04 - Mark: "Saw it! Adds a vibrant touch to the city. My weekend was all about catching up on some reading and the marathon on TV."</p>
                        <p>14:05 - Jeroen: "Sounds like a weekend well spent. I managed to get some gardening done. Now, shifting our focus, I have an interesting update from management before we discuss the event feedback."</p>
                        <p>14:06 - Dan: "Oh? Do tell, Jeroen."</p>
                        <p>14:07 - Jeroen: "They propose we brainstorm a slogan for our next event, something that reflects our goal for the year. It's a unique way to engage everyone and set a positive tone."</p>
                        <p>14:09 - Sara: "That’s exciting! It could really unify our vision for the event. But first, we should tackle the feedback."</p>
                        <p>14:10 - Dan: "Right, let’s start with the venue's location. The proximity to a train station was a highlight for its sustainability and convenience."</p>
                        <p>14:13 - Mark: "Absolutely, but let’s not overlook the calendar issue. We missed adding the event, causing some confusion."</p>
                        <p>14:15 - Jeroen: "A straightforward fix there. We’ll ensure better integration with our internal calendars."</p>
                        <p>14:17 - Sara: "Regarding the food, the feedback suggested it was too Dutch-centric. We need a menu that celebrates more international flavors."</p>
                        <p>14:20 - Dan: "Good point, Sara. Also, there was concern about the passive nature of the event. Too much sitting and listening."</p>
                        <p>14:23 - Mark: "We could introduce more interactive sessions, maybe even workshops or short physical activities."</p>
                        <p>14:25 - Jeroen: "And let's not forget the packed schedule. It left little room for networking, which many missed."</p>
                        <p>14:27 - Sara: "Networking is key. A lounge or dedicated time for it would be beneficial."</p>
                        <p>14:30 - Dan: "These are all excellent points. Now, about the slogan Jeroen mentioned, it could be a great way to involve everyone in the event’s theme."</p>
                        <p>14:33 - Mark: "Maybe a contest among employees to come up with the slogan? It could foster creativity and ownership."</p>
                        <p>14:35 - Jeroen: "A contest is a great idea. It encourages participation and excitement about our goals."</p>
                        <p>14:37 - Dan: "Let’s pencil that in. Moving on, how can we ensure everyone knows about the event well in advance?"</p>
                        <p>14:40 - Sara: "A multi-channel communication plan might work, incorporating emails, internal posts, and maybe even a countdown."</p>
                        <p>14:43 - Mark: "Back to the physical activity point, how about a mini-marathon or a group yoga session during the event?"</p>
                        <p>14:45 - Jeroen: "Yoga could be a hit. It's inclusive and refreshing. Plus, it ties back to our discussion on health and wellbeing."</p>
                        <p>14:48 - Dan: "I like where this is heading. Also, should we consider external speakers or workshops to add more variety?"</p>
                        <p>14:50 - Sara: "External speakers on trending topics could definitely spice things up. Maybe someone on sustainability, reflecting our venue choice?"</p>
                        <p>14:53 - Mark: "Speaking of external, how about a local food festival concept for catering? Offers variety and supports local businesses."</p>
                        <p>14:55 - Jeroen: "That's innovative, Mark. Ties in with the diverse food options we were aiming for."</p>
                        <p>14:57 - Dan: "Great brainstorming, team. Let's start wrapping up. Sara, can you take the lead on exploring catering options?"</p>
                        <p>15:00 - Sara: "Certainly, Dan. I’ll also look into the survey for menu preferences."</p>
                        <p>15:02 - Mark: "I’ll research interactive session ideas and potential speakers. Also, the team-building retreat could be an exciting pre-event activity."</p>
                        <p>15:05 - Jeroen: "I’ll coordinate with management on the goal for the year and start drafting ideas for the slogan contest framework."</p>
                        <p>15:08 - Dan: "Fantastic. We’ve covered substantial ground today, from addressing event feedback to brainstorming our slogan initiative. Let’s reconvene next week with updates on our assigned tasks. Thanks for a productive meeting, everyone!"</p>
                    </div>

                    <Link to={require("../../assets/workshop/workshop-transcript-download.txt")} target={"_blank"} download
                          className={"px-10 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white block"}>
                            <span className={"flex center justify-center"}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                     stroke="currentColor" className="w-6 h-6 mr-5">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"/>
                                </svg>
                                <span>Download transcript (txt)</span>
                            </span>
                    </Link>

                    <div className={"pt-5"}>
                        <BaseButton url={"/workshop/event-timeline#timeline"} styles="max-w-full px-8 py-4 mr-5 mb-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                            Next: Event timeline
                        </BaseButton>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WorkshopTranscript;
