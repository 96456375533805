import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect} from "react";
import QRCodeGenerator from "../../components/QRCodeGenerator";
import RoleEnum from "../../components/enums/RoleEnum";
import VerifyCertificateForm from "../../components/dashboard/dashboard/verify-certificate-form";

const Trainer = () => {
    const { user } = useAuth({ middleware: 'trainer' });

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Trainer"

        if (user && user?.role < RoleEnum.Trainer) {
            window.location.href = '/dashboard'
        }

    }, [user]);
    

    return(
        user && user?.role >= RoleEnum.Trainer ?
            <AppLayout
                header={
                    <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                        Trainer Dashboard
                    </h2>
                }>
                <div className="py-12 mx-3 lg:mx-0">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="flex flex-col">
                                <div className="welcome-section bg-white overflow-hidden shadow-sm sm:rounded-lg mb-4 flex-grow">
                                    <div className="p-6 bg-white">
                                        <h1 id="welcome" className="text-[2rem]">
                                            Welcome <span className="text-header-gradient">{user?.name}</span>!
                                        </h1>
                                        <p>Here are some tools for trainers!</p>
                                    </div>
                                </div>
                                <div className="verify-certificate bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                    <div className="p-6 bg-white">
                                        <VerifyCertificateForm />
                                    </div>
                                </div>
                            </div>
                            <div className="qrcode bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                <div className="p-6 bg-white">
                                    <QRCodeGenerator />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout>
            :
            <AppLayout
                header={
                    <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                        Authenticating...
                    </h2>
                }>
            </AppLayout>
    )
}

export default Trainer
