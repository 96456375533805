import React from "react";

interface BackToTopProps {
    sticky?: boolean;
}
const BackToTop: React.FC<BackToTopProps> = ({ sticky }) => {
    let addClasses = "w-full flex justify-center";
    if (sticky) { addClasses += " sticky bottom-10" }
    return(
        <div className={addClasses}>
        <a
            data-aos="flip-down"
            data-aos-delay="150"
            href="#"
            className="px-6 py-3 flex items-center space-x-2 bg-[#FAFAFA] hover:bg-gray-100 hover:shadow-md border border-[#DDDDDD] rounded-md text-gray-700"
        >
            <span>Back to top</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-4"
            >
                <path
                    fillRule="evenodd"
                    d="M11.47 2.47a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06l-6.22-6.22V21a.75.75 0 01-1.5 0V4.81l-6.22 6.22a.75.75 0 11-1.06-1.06l7.5-7.5z"
                    clipRule="evenodd"
                />
            </svg>
        </a>
    </div>);
}

export default BackToTop;