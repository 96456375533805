import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import './assets/css/framework.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Documentation from "./pages/Documentation";
import Blogs from "./pages/Blogs";
import WorkshopTranscript from "./pages/workshop/WorkshopTranscript";
import Workshop from "./pages/Workshop";

import Oops from "./pages/Oops";
import FrameworkIntroduction from "./pages/documentation/FrameworkIntroduction";
import Introduction from "./pages/documentation/Introduction";
import PromptEngineeringDocumentation from "./pages/documentation/PromptEngineering";
import FrameworkDocumentation from "./pages/documentation/FrameworkDocumentation";
import VisualFrameworkDocumentation from "./pages/documentation/VisualFrameworkDocumentation";
import PromptTechniquesDocumentation from "./pages/documentation/PromptTechniques";
import FrameworkCheatSheet from "./pages/documentation/FrameworkCheatSheet";
import Login from "./pages/dashboard/login";
import Register from "./pages/dashboard/register";
import Dashboard from "./pages/dashboard/dashboard";
import PromptArchitectureDocumentation from "./pages/documentation/PromptArchitecture";
import ReactGA from 'react-ga4';
import AdversarialPromptingDocumentation from "./pages/documentation/AdversarialPrompting";
import PromptInjectionDocumentation from "./pages/documentation/PromptInjections";
import PromptLeakingDocumentation from "./pages/documentation/PromptLeaking";
import PromptJailbreakDocumentation from "./pages/documentation/PromptJailbreak";
import Feedback from "./pages/Feedback";
import WorkshopIntroduction from "./pages/workshop/Introduction";
import WorkshopEventTimeline from "./pages/workshop/EventTimeline";
import QuizApp from "./pages/test/Quiz";
import Logout from "./pages/dashboard/logout";
import ContentPolicy from "./pages/ContentPolicy";
import Admin from "./pages/dashboard/admin";
import QuizTerms from "./pages/QuizTerms";
import AccountTerms from "./pages/AccountTerms";
import CaipTest from "./pages/CaipTest";
import AdminTests from "./pages/dashboard/admin-tests";
import Profile from "./pages/dashboard/profile";
import Trainer from "./pages/dashboard/trainer";
import ShowCertificateOnline from "./pages/test/ShowCertificateOnline";
import Caip1IPE from "./pages/test-details/Caip1IPE";

AOS.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (localStorage.getItem('darkMode') === 'true') {
    document.getElementById('root')?.classList.add("dark");
    document.getElementById('root')?.classList.remove("light");
}else {
    document.getElementById('root')?.classList.add("light");
    document.getElementById('root')?.classList.remove("dark");
}

if (localStorage.getItem('cookies') === "0") {
    ReactGA.initialize('G-CX9LL68KRJ');
}

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/documentation" element={<Documentation/>}>
                    <Route index path={"framework/introduction"} element={<FrameworkIntroduction/>}/>
                    <Route index path={"large-language-models"} element={<Introduction/>}/>
                    <Route index element={<Navigate to="/documentation/framework/introduction" replace/>}/>
                    <Route path={"prompt-engineering"} element={<PromptEngineeringDocumentation/>}/>
                    <Route path={"framework"} element={<FrameworkDocumentation/>}/>
                    <Route path={"framework/visual"} element={<VisualFrameworkDocumentation/>}/>
                    <Route path={"prompt-techniques"} element={<PromptTechniquesDocumentation/>}/>
                    <Route path={"adversarial-prompting"} element={<AdversarialPromptingDocumentation/>}/>
                    <Route path={"adversarial-prompting/prompt-injections"} element={<PromptInjectionDocumentation/>}/>
                    <Route path={"adversarial-prompting/prompt-leaking"} element={<PromptLeakingDocumentation/>}/>
                    <Route path={"adversarial-prompting/prompt-jailbreak"} element={<PromptJailbreakDocumentation/>}/>
                    <Route path={"prompt-architecture"} element={<PromptArchitectureDocumentation/>}/>
                    <Route path="framework/cheatsheet" element={<FrameworkCheatSheet/>}/>
                </Route>
                <Route path="/workshop" element={<Workshop/>}>
                    <Route index element={<WorkshopIntroduction />}/>
                    <Route path={"transcript"} element={<WorkshopTranscript />}/>
                    <Route path={"event-timeline"} element={<WorkshopEventTimeline />}/>
                </Route>

                <Route path="/blogs" element={<Blogs/>}/>
                <Route path="/content-policy" element={<ContentPolicy />}/>
                <Route path="/test/terms" element={<QuizTerms />}/>
                <Route path="/account/terms" element={<AccountTerms />}/>
                <Route path="/test/" element={<CaipTest />}/>
                <Route path="/test/caip-1" element={<Caip1IPE />}/>
                <Route path="/feedback" element={<Feedback />}/>

                <Route path="/register" element={<Register/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/logout" element={<Logout />}/>

                <Route path="/dashboard/overview" element={<Dashboard/>}/>
                <Route path="/dashboard/profile" element={<Profile />}/>
                <Route path="/dashboard/quiz" element={<QuizApp />}/>

                <Route path="/dashboard/trainer" element={<Trainer />}/>

                <Route path="/dashboard/admin" element={<Admin />}/>
                <Route path="/dashboard/admin/test/questions" element={<AdminTests />}/>

                <Route path={"/certificate/show/:id"} element={<ShowCertificateOnline />}/>

                {/* Redirects */}
                <Route path="/framework" element={<Navigate to="/documentation/framework" replace/>}/>
                <Route path="/framework/techniques" element={<Navigate to="/documentation/prompt-techniques" replace/>}/>
                <Route path="/documentation/prompt-injections"
                       element={<Navigate to="/documentation/adversarial-prompting" replace/>}/>
                <Route path="/framework/cheatsheet"
                       element={<Navigate to="/documentation/framework/cheatsheet" replace/>}/>

                <Route path="/dashboard" element={<Navigate to="/dashboard/overview" replace/>}/>

                <Route path={"*"} element={<Oops/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
