import TicketStatusEnum from "../../enums/TicketStatusEnum";
import React, {useEffect, useState} from "react";
import TicketUpdate from "./TicketUpdates";
import ReplyTicketModal from "./ReplyTicketModal";
import axios from "../../../libs/axios";
import {useAuth} from "../../../hooks/auth";

const Tickets = ({tickets: initialTickets, category = null, filterCategory = null, filterStatus = null, showOnlyStatus = null, showMax = null}) => {
    const { user } = useAuth({ middleware: 'auth' })
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [currentTicketId, setCurrentTicketId] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [tickets, setTickets] = useState(initialTickets || []);

    function replyToTicket(ticketId) {
        setCurrentTicketId(ticketId);
        setShowReplyModal(true);
    }

    async function handleReplySubmit() {
        try {
            axios.post(`/ticket/reply`, {
                ticket_id: currentTicketId,
                message: replyText
            }).then(response => {
                // Update the ticket list
                setTickets(tickets.map(ticket => ticket.id === currentTicketId ? {
                    ...ticket,
                    updates: [...ticket.updates, {
                        user: user,
                        description: replyText,
                        updated_at: new Date().toISOString()
                    }],
                    status: response.data.new_ticket_status // update the status of the ticket
                } : ticket));
            }).catch(() => {
                window.location.reload();
            });

            setShowReplyModal(false);
            setReplyText('');

        } catch (error) {
            if (error.status === 401) {
                window.location.reload()
            }
        }
    }


    useEffect(() => {
        let filteredTickets = [...tickets];

        if (category) {
            filteredTickets = filteredTickets.filter(ticket => ticket.category === category);
        }
        if (filterCategory) {
            filteredTickets = filteredTickets.filter(ticket => ticket.category !== filterCategory);
        }

        if (filterStatus !== null) {
            filteredTickets = filteredTickets.filter(ticket => ticket.status !== filterStatus);
        }

        if (showOnlyStatus !== null) {
            filteredTickets = filteredTickets.filter(ticket => ticket.status === showOnlyStatus);
        }

        if (showMax !== null) {
            filteredTickets = filteredTickets.slice(0, showMax);
        }

        setTickets(filteredTickets);
    }, [category, filterStatus, showOnlyStatus, showMax]);

    return(
        <div className="grid grid-cols-1 gap-4">
            {tickets && tickets.map((ticket, index) => (
                <div key={index} className="border rounded-lg shadow-md p-6 bg-white">
                    <h2 className="text-lg font-bold text-blue-600 mb-4">Ticket # {tickets.length - index} {ticket.title && ` - ` + ticket.title}</h2>
                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <strong className="text-gray-700">Created at:</strong>
                            <span className="text-gray-600">
                                {ticket.created_at ? new Date(ticket.created_at).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : 'N/A'}
                            </span>
                        </div>
                        <div className="flex justify-between">
                            <strong className="text-gray-700">Status:</strong>
                            <span className={ticket.status === TicketStatusEnum.Open ? 'text-green-600' : ticket.status === TicketStatusEnum.Pending ? 'text-yellow-600' : 'text-red-600'}>
                                  {ticket.status === TicketStatusEnum.Open ? 'Open' : ticket.status === TicketStatusEnum.Pending ? 'Pending' : 'Closed'}
                              </span>
                        </div>
                        <div className={"pb-5"}>
                            <strong className="text-gray-700">Description:</strong>
                            <div className="text-gray-600 mt-3">{ticket.description}</div>
                        </div>

                        {ticket.updates && ticket.updates?.slice(-3).map((update, index) => (
                            <TicketUpdate key={index} update={update} />
                        ))}

                        {(ticket.status !== TicketStatusEnum.Pending && ticket.status !== TicketStatusEnum.Closed) && (
                        <div className="flex justify-end">
                            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={() => {replyToTicket(ticket.id)}}>Reply</button>
                        </div>
                        )}
                    </div>
                </div>
            ))}
            {tickets && tickets.length === 0 && <div className="border text-gray-500 rounded-lg shadow-md p-6 bg-white">No tickets found.</div>}

            {showReplyModal && (
                <ReplyTicketModal
                    showReplyModal={showReplyModal}
                    setShowReplyModal={setShowReplyModal}
                    ticketList={tickets}
                    currentTicketId={currentTicketId}
                    replyText={replyText}
                    setReplyText={setReplyText}
                    handleReplySubmit={handleReplySubmit}
                    status={tickets.find(ticket => ticket.id === currentTicketId)?.status}
                />
            )}
        </div>
    );
}

export default Tickets;