// Layouts
import DefaultLayout from "../layouts/default";
// Components
import React, {useEffect, useRef} from "react";
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import {Outlet, useLocation} from "react-router-dom";
import BaseButton from "../components/base/Button";

function Documentation() {
    const location = useLocation();
    const lastHash = useRef('');

    useEffect(() => {
        goToLocation(-130);
    });

    // listen to location change using useEffect with location as dependency
    // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
    useEffect(() => {
        goToLocation(-20);
    });

    function goToLocation(yOffset: number) {

        if (location.hash) {
            lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                const element = document.getElementById(lastHash.current);
                if (element) {
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                }

                lastHash.current = '';
            }, 50);
        }
    }

  return (
      <div className="Framework">
        <DefaultLayout>
          <HeaderBanner title="Framework"
                        buttons={[
                            {title: "Crafting Phase", url: "/documentation/framework#craft"},
                            {title: "Validation Phase", url: "/documentation/framework#validate"},
                            {title: "Enhancement Phase", url: "/documentation/framework#enhance"}
                        ]}
          />

          <Outlet />

          <div className="lg:hidden">
              <section className="relative max-w-full overflow-hidden">
                  <div className="w-full py-5 flex flex-col items-center">
                      <h1
                          data-aos="fade-right"
                          data-aos-once="true"
                          className="text-[2.5rem] sm:text-5xl xl:text-6xl font-bold leading-tight capitalize pb-5 dark:text-white"
                      >
                          <span className="text-header-gradient">Read more</span>
                      </h1>
                          <div
                              data-aos="fade-up"
                              data-aos-once="true"
                              data-aos-delay="700"
                              className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-2"
                          >
                              {
                                  location.pathname === "/documentation/prompt-engineering" ? "" :
                                      <BaseButton url="/documentation/prompt-engineering" styles="max-w-full px-8 py-4 border border-[#0c66ee] dark:text-white">Prompt Engineering</BaseButton>
                              }
                              {
                                  location.pathname === "/documentation/framework" ? "" :
                                      <BaseButton url="/documentation/framework" styles="max-w-full px-8 py-4 border border-[#0c66ee] dark:text-white">Framework</BaseButton>
                              }
                              {
                                  location.pathname === "/documentation/framework/visual" ? "" :
                                      <BaseButton url="/documentation/framework/visual" styles="max-w-full px-8 py-4 border border-[#0c66ee] dark:text-white">Framework IVPE</BaseButton>
                              }

                              {/*{*/}
                              {/*    location.pathname === "/documentation/prompt-techniques" ? "" :*/}
                              {/*        <BaseButton url="/documentation/prompt-techniques" styles="max-w-full px-8 py-4 border border-[#0c66ee] dark:text-white">Prompting Techniques</BaseButton>*/}
                              {/*}*/}
                              {/*{*/}
                              {/*    location.pathname === "/documentation/prompt-injections" ? "" :*/}
                              {/*        <BaseButton url="/documentation/prompt-injections" styles="max-w-full px-8 py-4 border border-[#0c66ee] dark:text-white">Prompt Injections</BaseButton>*/}
                              {/*}*/}
                      </div>
                  </div>
              </section>
          </div>

          <ThreePhasesSection />

          <BackToTop sticky={true} />
        </DefaultLayout>
      </div>
  );
}

export default Documentation;
