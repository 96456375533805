import LandingStep from "../landing/Step";
import React from "react";

const ThreePhasesSection = () => {

    const steps = [
        {
            img: "craft-icon.png",
            title: "CRAFT",
            description:
                "Craft (write) the prompt with the following elements: Context, Register, Acting Role, Format, and Task.",
        },
        {
            img: "ing-icon.png",
            title: "ING",
            description:
                "Validate the prompt and ensure it maintains an interactive approach. Keep in mind the importance of non-disclosure and staying goal-driven throughout the process.",
        },
        {
            img: "ai-icon.png",
            title: "AI",
            description:
                "Continuously assess and refine the output based on the prompts output to improve the overall quality.",
        },
    ];

    return(
        <section className="bg-gradient-to-b from-gray-50 to-gray-100 relative max-w-full sm:mx-4 xl:mx-10 my-16 shadow sm:rounded-2xl overflow-hidden
        dark:from-gray-900 dark:to-gray-950">
            <div className="w-full py-16 flex flex-col items-center">
                <h2
                    data-aos="flip-down"
                    className="text-3xl sm:text-4xl font-semibold text-center dark:text-white"
                >
                    The <span className="text-header-gradient">three phases</span>
                </h2>
                <div
                    data-aos="fade-up"
                    className="relative w-full flex flex-col lg:flex-row items-center justify-between space-y-12 lg:space-y-0 px-4 xl:px-10 mt-16 sm:mt-8"
                >
                    {steps.map((step, index) => {
                        return <LandingStep step={step} key={index}/>;
                    })}
                    <img
                        src={require("../../assets/img/getting-started/arrow.png")}
                        className="hidden lg:inline-block absolute top-32 left-64 xl:left-[22rem] w-24 xl:w-[9.5rem]"
                        alt=""
                    />
                    <img
                        src={require("../../assets/img/getting-started/arrow.png")}
                        className="hidden lg:inline-block absolute top-32 right-64 xl:right-[22rem] w-24 xl:w-[9.5rem]"
                        alt=""
                    />
                </div>
            </div>
        </section>
    );
}

export default ThreePhasesSection;