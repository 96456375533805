import React from 'react';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return (
        <div className="flex items-center justify-center space-x-2 mt-4 mb-6">
            <button
                onClick={() => onPageChange(1)}
                disabled={isFirstPage}
                className={`p-2 rounded ${
                    isFirstPage
                        ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
            >
                <ChevronsLeft size={20} />
            </button>
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={isFirstPage}
                className={`p-2 rounded ${
                    isFirstPage
                        ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
            >
                <ChevronLeft size={20} />
            </button>
            <span className="text-gray-700">
        Page {currentPage} of {totalPages}
      </span>
            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={isLastPage}
                className={`p-2 rounded ${
                    isLastPage
                        ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
            >
                <ChevronRight size={20} />
            </button>
            <button
                onClick={() => onPageChange(totalPages)}
                disabled={isLastPage}
                className={`p-2 rounded ${
                    isLastPage
                        ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
            >
                <ChevronsRight size={20} />
            </button>
        </div>
    );
};

export default Pagination;