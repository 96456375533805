import React, {useEffect, useRef} from "react";
// Layouts
import DefaultLayout from "../layouts/default";
// Components
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import {useLocation} from "react-router-dom";

function QuizTerms() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Test terms and conditions"
  }, []);

  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
    goToLocation(-130);
  });

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    goToLocation(-20);
  });

  function goToLocation(yOffset: number) {

    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        const element = document.getElementById(lastHash.current);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }

        lastHash.current = '';
      }, 50);
    }
  }

  return (
    <div className="Blogs dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Test Terms and Conditions"  />

        <section className="relative flex max-w-full sm:mx-6 shadow sm:rounded-2xl overflow-hidden dark:bg-slate-900 dark:text-white">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 dark:text-white"
          data-aos="fade-up">
            <div className={"max-w-6xl mx-8"} id="test-terms">
              <h3>
                Test - <span className="text-header-gradient">Terms and Conditions</span>
              </h3>
              <p className="text-sm text-gray-600 mb-8">Effective Date: August 1st, 2024</p>

              <p className=" mb-8">
                These Terms and Conditions outline the rules and guidelines for participating in the certification test provided by Crafting AI Prompts ("the Test"). By participating in the Test, you agree to comply with the following terms and conditions:
              </p>

              <h2 className="text-2xl font-semibold mb-4">1. Prohibited Actions</h2>
              <p><strong>1.1 Using the Internet or External Resources</strong><br />
                Participants are strictly prohibited from using the internet or any external resources during the Test. This includes, but is not limited to, searching for answers, consulting online references, or communicating with others via any digital platform. The Test is designed to evaluate your knowledge and understanding independently, and any use of unauthorized resources will be considered a violation of these Terms and Conditions.
              </p>

              <p><strong>1.2 Saving or Sharing Test Content</strong><br />
                Participants are strictly prohibited from saving, copying, or sharing any of the Test questions or content with others in any form, including but not limited to screenshots, written notes, or digital reproductions. The integrity and value of the certification awarded upon successful completion of the Test depend on ensuring that the Test is taken under fair and controlled conditions.
              </p>

              <p><strong>1.3 Unauthorized Distribution</strong><br />
                You may not distribute, post, or otherwise share Test questions or content on any public or private platform, including social media, websites, forums, or communication apps. Any attempt to share or distribute Test content will be considered a violation of these Terms and Conditions and may result in disqualification from receiving the certification.
              </p>

              <h2 className="text-2xl font-semibold mb-4">2. Account Validity and Limitations</h2>
              <p><strong>2.1 Valid Email Requirement</strong><br />
                Participants must register with an account that includes a valid email address. This email will be used for important communications and the issuance of certificates. If it is discovered that the email address provided is invalid or fraudulent, Crafting AI Prompts reserves the right to ban the account and invalidate any certificates associated with that account.
              </p>

              <p><strong>2.2 One Account Per User</strong><br />
                Each participant is allowed to create and use only one account. If it is found that a participant has created multiple accounts, all associated accounts may be banned, and any certificates earned under these accounts will be rendered invalid.
              </p>


              <h2 className="text-2xl font-semibold mb-4">3. Certificate Integrity</h2>
              <p><strong>3.1 Ensuring Fairness</strong><br />
                The purpose of this Test is to accurately assess your knowledge and understanding of the subject matter. By agreeing to these terms, you acknowledge the importance of maintaining the confidentiality of the Test questions to ensure that the certification reflects true knowledge and not merely access to shared information.
              </p>

              <p><strong>3.2 Disqualification and Consequences</strong><br />
                Any violation of the terms outlined in this document, including using the internet, saving, or sharing Test content, will result in immediate disqualification from the Test. Additionally, any certificates awarded based on such a violation will be considered null and void. Further action may be taken to revoke any associated benefits or recognitions.
              </p>

              <h2 className="text-2xl font-semibold mb-4">4. Technical Issues and Test Integrity</h2>
              <p><strong>4.1 Responsibility for Technical Issues</strong><br />
                If the Test crashes, the browser is closed, or any other form of interruption occurs during the Test, Crafting AI Prompts is not responsible for any lost progress or incomplete submissions. In such cases, the penalty period, which requires you to wait before retaking the Test, will remain in place.
              </p>

              <p><strong>4.2 Account Name Validation</strong><br />
                Before starting the Test, you must validate your account name. The name you provide will be used on your certificate, and by proceeding, you confirm that the account details are accurate and correctly reflect the name you wish to appear on your certification.
              </p>

              <h2 className="text-2xl font-semibold mb-4">5. Retake Policy and Reasoning</h2>
              <p><strong>5.1 Retake Period Explanation</strong><br />
                Crafting AI Prompts has implemented a mandatory waiting period before you can retake the Test if you do not pass or if your Test is invalidated due to technical issues. This waiting period is necessary to ensure that participants do not quickly remember and reuse the questions. Despite the Test drawing from a large pool of questions that are randomly selected, this delay helps maintain the integrity of the assessment and ensures it remains a valid measure of your knowledge.
              </p>

              <h2 className="text-2xl font-semibold mb-4">6. Appeals Process</h2>
              <p><strong>6.1 Submitting an Appeal</strong><br />
                If you believe that your Test results are incorrect or if you experienced significant technical issues, you may submit an appeal by emailing <a href="mailto:info@craftingaiprompts.org">info@craftingaiprompts.org</a>. Please include all relevant evidence and a detailed explanation of your issue. Crafting AI Prompts will review your appeal, but submitting an appeal does not guarantee that you will be allowed to retake the Test immediately or without the mandatory waiting period.
              </p>

              <h2 className="text-2xl font-semibold mb-4">7. Participant Responsibility</h2>
              <p><strong>7.1 Individual Effort</strong><br />
                You agree to complete the Test individually, without assistance from others or use of unauthorized materials. The certification you earn is a reflection of your personal effort and understanding, and you are responsible for ensuring the authenticity of your participation.
              </p>

              <p><strong>7.2 Acknowledgment of Terms</strong><br />
                By participating in the Test, you acknowledge that you have read, understood, and agree to these Terms and Conditions. Compliance with these terms is necessary to maintain the integrity of the certification process.
              </p>

              <h2 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
              <p><strong>8.1 Liability Disclaimer</strong><br />
                Crafting AI Prompts shall not be held liable for any direct, indirect, incidental, or consequential damages resulting from your participation in the Test, including but not limited to technical failures, data breaches, or loss of data. Participation in the Test is at your own risk, and you agree that Crafting AI Prompts' liability is limited to the maximum extent permitted by law.
              </p>

              <h2 className="text-2xl font-semibold mb-4">9. Modifications to Terms and Conditions</h2>
              <p><strong>9.1 Policy Updates</strong><br />
                We reserve the right to modify or update these Terms and Conditions at any time. Any changes will be posted on this page, with the updated effective date at the top. Participants will have 30 days from the effective date to comply with the new terms.
              </p>

              <p>
                Thank you for respecting these terms and helping us maintain the integrity of the certification process. By starting the Test, you acknowledge that you have read and agree to all terms and conditions. Your cooperation ensures that the value of the certification remains high and is respected by all who earn it.
              </p>

            </div>
          </div>
        </section>

        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default QuizTerms;
