import {NavLink} from "react-router-dom";
import React from "react";

const AuthCard = ({ logo, children }) => (
  <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
    <div>{logo}</div>
    <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg mb-4">
      {children}
    </div>
      <NavLink
          to="/"
          className="underline justify-end text-sm text-blue-900 hover:text-gray-900 mr-3"
      >
          Go back to the website
      </NavLink>
  </div>
)

export default AuthCard
