import React, {useEffect, useRef} from "react";
// Layouts
import DefaultLayout from "../layouts/default";
// Components
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import {useLocation} from "react-router-dom";

function AccountTerms() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Account terms and conditions"
  }, []);

  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
    goToLocation(-130);
  });

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    goToLocation(-20);
  });

  function goToLocation(yOffset: number) {

    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        const element = document.getElementById(lastHash.current);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }

        lastHash.current = '';
      }, 50);
    }
  }

  return (
    <div className="Blogs dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Account - Terms and Conditions"  />

        <section className="relative flex max-w-full sm:mx-6 shadow sm:rounded-2xl overflow-hidden dark:bg-slate-900 dark:text-white">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 dark:text-white"
          data-aos="fade-up">
            <div className={"max-w-6xl mx-8"} id="content-policy">
              <h3>
                Account Registration - <span className="text-header-gradient">Terms and Conditions</span>
              </h3>
              <p className="text-sm text-gray-600 mb-8">Effective Date: August 1st, 2024</p>

              <p className=" mb-8">
                These Terms and Conditions outline the rules and guidelines for creating an account on the Crafting AI Prompts website ("the Website"). By registering an account, you agree to comply with the following terms and conditions:
              </p>

              <h2 className="text-2xl font-semibold mb-4">1. Account Registration Requirements</h2>
              <p><strong>1.1 Valid Email Address</strong><br />
                You must provide a valid email address when registering an account on the Website. This email will be used to validate your account and for important communications, including resolving any issues that may arise. We do not share your email address with any third parties.
              </p>

              <p><strong>1.2 Accurate Information</strong><br />
                When creating an account, you are required to provide accurate and truthful information. This includes your email address, name, and any other details requested during registration. Crafting AI Prompts reserves the right to suspend or terminate accounts that are found to contain false or misleading information.
              </p>

              <h2 className="text-2xl font-semibold mb-4">2. Account Security and Usage</h2>
              <p><strong>2.1 One Account Per User</strong><br />
                Each individual is permitted to create and use only one account on the Website. If it is determined that you have created multiple accounts, all associated accounts may be suspended or terminated without notice.
              </p>

              <p><strong>2.2 Account Security</strong><br />
                You are responsible for maintaining the confidentiality of your account login information. Crafting AI Prompts is not liable for any unauthorized access to your account resulting from your failure to protect your login credentials.
              </p>

              <h2 className="text-2xl font-semibold mb-4">3. Use of Email Address</h2>
              <p><strong>3.1 Communication Purposes</strong><br />
                Your email address will be used solely for the purpose of validating your account and for sending you important notifications related to your account activity. We do not share your email address with any third parties or use it for purposes unrelated to your account.
              </p>

              <p><strong>3.2 Issue Resolution</strong><br />
                In the event that issues arise with your account, such as login difficulties or account suspension, we may use your email address to communicate with you and resolve the issue. It is your responsibility to ensure that the email address associated with your account is current and accessible.
              </p>

              <h2 className="text-2xl font-semibold mb-4">4. Modifications to Terms and Conditions</h2>
              <p><strong>4.1 Policy Updates</strong><br />
                We reserve the right to modify or update these Terms and Conditions at any time. Any changes will be posted on this page, with the updated effective date at the top. You will have 30 days from the effective date to comply with the new terms. Continued use of your account after the effective date constitutes your acceptance of the updated terms.
              </p>

              <h2 className="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
              <p><strong>5.1 Liability Disclaimer</strong><br />
                Crafting AI Prompts shall not be held liable for any direct, indirect, incidental, or consequential damages resulting from your use of the Website, including but not limited to technical failures, data breaches, or loss of data. Your use of the Website and the creation of an account are at your own risk, and you agree that Crafting AI Prompts' liability is limited to the maximum extent permitted by law.
              </p>

              <p>
                Thank you for registering an account with Crafting AI Prompts. By creating an account, you acknowledge that you have read and agree to all terms and conditions. Your cooperation helps us ensure a secure and trustworthy environment for all users.
              </p>
            </div>
          </div>
        </section>

        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default AccountTerms;
