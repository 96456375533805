import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../libs/axios";
import CertificateGenerator from "../dashboard/certificate";
import { AlertCircle, CheckCircle } from "lucide-react";

interface Certificate {
    certificate_id: string;
    username: string;
    date: string;
    valid: boolean;
}

const ShowCertificateOnline: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [certificate, setCertificate] = useState<Certificate | null>(null);
    let navigate = useNavigate();

    useEffect(() => {
        // Check if the $id is this format: 12345-12345-12345
        if (id && !/^\d{5}-\d{5}-\d{5}$/.test(id)) {
            setCertificate({certificate_id: "", username: "", date: "", valid: false})
        }else {
            axios.get(`public/verify-certificate/${id}`)
                .then(response => {
                    setCertificate(response.data);
                })
                .catch(error => {
                    setCertificate({certificate_id: "", username: "", date: "", valid: false})
                });
        }
    }, [id]);

    if (!certificate) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    const validityColor = certificate.valid ? "green" : "red";
    const validityText = certificate.valid ? "Valid" : "Invalid";
    const ValidityIcon = certificate.valid ? CheckCircle : AlertCircle;

    return (
        <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
                {certificate.valid ? (
                <div className="px-4 py-5 sm:p-6 flex flex-col md:flex-row">
                    <div className="md:w-1/2 pr-12">
                        <div>
                            <p className="text-sm text-gray-600 uppercase">Name</p>
                            <p className="text-lg font-semibold text-gray-800">{certificate.username}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600 uppercase">Certificate ID</p>
                            <p className="text-lg font-mono text-gray-800">{certificate.certificate_id}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600 uppercase">Issue Date</p>
                            <p className="text-lg text-gray-800">{certificate.date}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600 uppercase">Issuing Authority</p>
                            <p className="text-lg text-gray-800">Crafting AI Prompts</p>
                        </div>

                        <hr />

                        <div className="text-gray-600 mb-8">
                            <p>You can also take this test. <br />
                                Please click on the button below to access the available tests.</p>
                        </div>

                        <div className="flex justify-center">
                            <button
                                onClick={() => navigate('/test')}
                                className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                            >
                                Take the test yourself!
                            </button>
                        </div>
                    </div>


                    <div className="md:w-1/2">
                        <div className={`flex items-center justify-center text-${validityColor}-600`}>
                            <ValidityIcon className="w-8 h-8 mr-2 pt-0" />
                            <h1 className={`text-3xl font-bold text-center pt-5 pl-4 text-${validityColor}-600`}>
                                {validityText} Certificate
                            </h1>
                        </div>
                        <div className="mb-8">
                            <CertificateGenerator
                                name={certificate.username}
                                certificateId={certificate.certificate_id}
                                dateCompleted={certificate.date}
                                showCertificateId={false}
                                showDownload={false}
                                preview={true}
                            />
                        </div>

                        <p className="mb-4 text-center">
                            <strong className="text-gray-900">{certificate.username}</strong> has completed the test.
                            <br />
                            This certificate is currently <strong className={`text-${validityColor}-600`}>{validityText.toLowerCase()}</strong>.
                        </p>
                        <p className="text-sm text-center">
                            For the original certificate, please contact the user.
                        </p>
                    </div>
                </div>
                    ) :
                   <div className="px-4 py-5 sm:p-6">
                        <div className={`mb-6 text-${validityColor}-600`}>
                            <div className="flex items-center justify-center">
                                <ValidityIcon className="w-8 h-8 mr-2 pt-0" />
                                <h1 className={`text-3xl font-bold text-center pt-5 pl-4 text-${validityColor}-600`}>
                                    {validityText} Certificate
                                </h1>
                            </div>
                            <p className="text-sm text-red-600 text-center">This certificate is not valid. Please contact the issuer for more information.</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default ShowCertificateOnline;