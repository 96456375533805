import axios from "../../../libs/axios";
import {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {useAuth} from "../../../hooks/auth";


const VerifyCertificateForm = () => {
    const blockUserForMinutes = 2;
    const maxRequestsBeforeBlock = 5;

    const [certificateId, setCertificateId] = useState('');
    const [verificationResult, setVerificationResult] = useState(null);
    const [error, setError] = useState(null);
    const { csrf } = useAuth();

    const verifyCertificate = async (certificateId) => {
        try {
            await csrf();
            const response = await axios.get(`/public/verify-certificate/${certificateId}`);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 429) {
                setError("Can't verify certificate. Too many requests");
                return null;
            }
            else if(error.response && error.response.status !== 404) {
                setError("Can't verify certificate. Please try again later.");

            }

            return null;
        }
    }

    const handleInputChange = (event) => {
        setCertificateId(event.target.value);
        setVerificationResult(null);
    };

    const [attemptCount, setAttemptCount] = useState(0);
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        const unblockTime = Number(localStorage.getItem('unblockTimeVerifyCert'));
        const now = Date.now();

        if (now < unblockTime) {
            setIsBlocked(true);
            setTimeout(() => {
                setIsBlocked(false);
                localStorage.removeItem('unblockTimeVerifyCert');
            }, unblockTime - now);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);

        if (isBlocked) {
            setError("You're blocked from verifying certificates. Please try again later.");
            return;
        }

        // Check if certificateId has the correct format
        const regex = /^\d{5}-\d{5}-\d{5}$/;
        if (!regex.test(certificateId)) {
            setVerificationResult(false);
            setError("Invalid certificate ID format. Please use the format: 12345-12345-12345");
            return;
        }

        const result = await verifyCertificate(certificateId);
        setVerificationResult(result?.valid === true);

        setAttemptCount(prevCount => prevCount + 1);
        if (attemptCount >= maxRequestsBeforeBlock) {
            setIsBlocked(true);
            const now = Date.now();
            const unblockTime = now + blockUserForMinutes * 60 * 1000;
            localStorage.setItem('unblockTimeVerifyCert', unblockTime.toString());

            setTimeout(() => {
                setIsBlocked(false);
                setAttemptCount(0);
                localStorage.removeItem('unblockTimeVerifyCert');
            }, blockUserForMinutes * 60 * 1000);
        }

    };

    return (
        <>
            <h3>Verify Certificate ID</h3>
            <p>Insert a certificate ID to validate if it's valid. Please note, you can only verify {maxRequestsBeforeBlock} certificates per {blockUserForMinutes} minutes.</p>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <div className="flex w-full">
                    <input
                        type="text"
                        value={certificateId}
                        placeholder={"Certificate ID"}
                        onChange={handleInputChange}
                        className="w-9/12 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <div className="w-3/12 flex justify-center items-center text-2xl">
                        {verificationResult === null && <span>-</span>}
                        {verificationResult === true && error === null && <><FontAwesomeIcon icon={faCheckCircle} className="text-green-800 animate-pulse temporary-bounce-short" /> <span className={"ml-3 text-sm"}>valid</span></>}
                        {verificationResult === false && error === null &&<><FontAwesomeIcon icon={faTimesCircle} className="text-red-500 animate-pulse temporary-bounce-short" /> <span className={"ml-3 text-sm"}>invalid</span></>}
                    </div>
                </div>
                <div className={error !== null ? `block` : `hidden`}>
                    <p className={"text-sm text-red-800 pb-0 mb-0"}>{error !== null && <span>{error}</span>}</p>
                </div>
                <button
                    type="submit"
                    className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition-colors ${isBlocked || verificationResult !== null ? 'cursor-not-allowed bg-gray-500 hover:bg-gray-500' : ''}`}
                    disabled={isBlocked || verificationResult !== null}
                    title={isBlocked ? `You are blocked from verifying certificates for ${blockUserForMinutes} minutes.` : ''}
                >
                    Verify
                </button>
            </form>
        </>
    );
};

export default VerifyCertificateForm;